/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import moment from 'moment'
import * as Yup from 'yup'

import { FiUser, FiCheck, FiPercent, FiX } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import { BiCake } from 'react-icons/bi'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'

import InputSelect from '../../components/InputSelect'
import InputHidden from '../../components/InputHidden'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import ModalBox from '../../components/Modal'

import {
  UserData,
  Participant,
  ParticipantDetails,
} from '../../utils/interfaces'
import {
  Container,
  Content,
  BtnVoltar,
  BtnContato,
  RadioButton,
  BenefBox,
  ContentBenef,
  Line,
} from './styles'
import { FormSteps } from '../../components/FormSteps'
import calculaIdade from '../../utils/calculaIdade'

const NovoParticipante: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<ParticipantDetails>(
    'userDetails',
    {} as ParticipantDetails,
  )
  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [, setSaveAndAddNew] = useState(false)
  const [thisParticipantData] = useState<UserData>({} as UserData)
  const [thisParticipant, setThisParticipant] = useState<Participant>(
    {} as Participant,
  )
  const [thisParticipantDetails] = useState<ParticipantDetails>(
    {} as ParticipantDetails,
  )

  const [vlrProporcao, setVlrProporcao] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const [tipoBenef, setTipoBenef] = useState('1')
  const [grauParent, setGrauParent] = useState({
    label: userDetails.dcrGrauParentesco,
    value: userDetails.grauParentesco,
  })

  const history = useHistory()
  const location = useLocation()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          birthdate: Yup.string()
            .required('Campo obrigatório.')
            .min(10, 'Data de nascimento deve seguir o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(formData.birthdate.split('/').reverse().join('-')) ||
                formData.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  formData.birthdate.split('/').reverse().join('-'),
                ).isValid() || formData.birthdate === '',
            ),
          cpf: Yup.string()
            .required('Campo obrigatório.')
            .test('', 'CPF já utilizado em outro cadastro', function v() {
              const t2 =
                (participants.filter(
                  participant => participant.data.cpf === formData.cpf,
                ).length <= 0 &&
                  formData.cpf !== userData.cpf) ||
                formData.cpf === ''
              return t2
            })
            .test('', 'CPF inválido', function t() {
              const teste =
                validaCPF(formData.cpf.replaceAll('.', '').replace('-', '')) ||
                formData.cpf === ''
              return teste
            }),
        })

        await schema.validate(formData, { abortEarly: false })

        const dataForm = formData.birthdate.split('/').reverse().join('-')

        setThisParticipant({
          ...thisParticipant,
          data: {
            ...thisParticipantData,
            name: formData.name,
            cpf: formData.cpf,
            birthdate: formData.birthdate === '' ? '' : dataForm,
          },
          details: {
            ...thisParticipantDetails,
            tipoBen: tipoBenef,
            grauParentesco: grauParent.value,
            dcrGrauParentesco: grauParent.label,
            proporcao: vlrProporcao,
          },
        })

        setParticipants([
          ...participants,
          {
            ...thisParticipant,
            data: {
              ...thisParticipantData,
              name: formData.name,
              cpf: formData.cpf,
              birthdate: formData.birthdate === '' ? '' : dataForm,
            },
            details: {
              ...thisParticipantDetails,
              tipoBen: tipoBenef,
              grauParentesco: grauParent.value,
              dcrGrauParentesco: grauParent.label,
              proporcao: vlrProporcao,
            },
          },
        ])
        if (formData.addNew === 'true') {
          history.push(`${location.pathname}`)
          formRef.current?.reset()
        } else {
          history.push('/participants-list')
        }
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      grauParent,
      history,
      location.pathname,
      participants,
      setParticipants,
      thisParticipant,
      thisParticipantData,
      thisParticipantDetails,
      tipoBenef,
      userData,
      vlrProporcao,
    ],
  )

  const handleChangeGrauParentesco = useCallback(e => {
    const t = e
    setGrauParent(t)
  }, [])

  const handleAddNovo = useCallback(() => {
    setSaveAndAddNew(true)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = true
    formRef.current?.submitForm()
  }, [])

  const handleJustSave = useCallback(() => {
    setSaveAndAddNew(false)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = false
    formRef.current?.submitForm()
  }, [])

  const arr = participants.map(participant => participant.details.proporcao)
  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }

  const handleValidaProporcao = useCallback((prop, s) => {
    if (prop < 1 || prop > s) {
      const msg = `A proporção deve ser maior que 1 e menor que ${s}`
      setTxtModal(msg)
      setIsModalOpen(true)
    } else {
      setVlrProporcao(prop)
    }
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
    },
    [participants, setParticipants],
  )

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormSteps currentStep={3} />
          {participants.length > 0 ? (
            <BenefBox>
              <h3>Beneficiários</h3>
              {participants.map((participant, index) => (
                <ContentBenef key={index}>
                  <div>
                    <FiX onClick={() => handleRemove(index)} />
                    <div>
                      <strong>Vínculo: </strong>
                      <label>{participant.details.dcrGrauParentesco}</label>
                    </div>
                    <div>
                      <strong>Nome: </strong>
                      <label>{participant.data.name}</label>
                    </div>
                    <div>
                      <strong>Data de nascimento: </strong>
                      <label>
                        {participant.data.birthdate
                          .split('-')
                          .reverse()
                          .join('/')}
                      </label>
                    </div>
                    <div>
                      <strong>CPF: </strong>
                      <label>{participant.data.cpf}</label>
                    </div>
                  </div>
                  <Line />
                </ContentBenef>
              ))}
            </BenefBox>
          ) : null}
          <h3>Informe seus beneficiários:</h3>
          <p>
            O cônjuge e os filhos menores de 21 anos já são beneficiários legais
            automáticos. Na ausência dos beneficiários legais os beneficiários
            indicados deverão receber seus recursos financeiros.
          </p>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: thisParticipantData.name,
              cpf: thisParticipantData.cpf,
              tipoBen: thisParticipantDetails.tipoBen,
              birthdate:
                thisParticipantData.birthdate === undefined
                  ? ''
                  : thisParticipantData.birthdate
                      .split('-')
                      .reverse()
                      .join('/'),
              grauParentesco: grauParent.value,
              dcrGrauParentesco: grauParent.label,
            }}
          >
            <InputHidden name="addNew" type="hidden" />
            <InputSelect
              name="grauParentesco"
              value={grauParent}
              options={[
                { label: 'Conjuge', value: '1' },
                {
                  label: 'Filho(a) não emancipado menor de 21 anos',
                  value: '3',
                },
                { label: 'Companheiro(a)', value: '2' },
                { label: 'Filho(a) inválido(a)', value: '4' },
                { label: 'Pai/mãe com dependência econômica', value: '5' },
                {
                  label:
                    'Irmão não emancipado menor de 21 anos com dependência econômica',
                  value: '6',
                },
                {
                  label: 'Irmão inválido com dependência econômica',
                  value: '7',
                },
                {
                  label:
                    'Enteado não emancipado menor de 21 anos com dependência econômica',
                  value: '8',
                },
                {
                  label: 'Enteado inválido com dependência econômica',
                  value: '9',
                },
                {
                  label:
                    'Menor tutelado não emancipado menor de 21 anos com dependência econômica',
                  value: '10',
                },
                {
                  label: 'Menor tutelado inválido com dependência econômica',
                  value: '11',
                },
                { label: 'Outros', value: '0' },
              ]}
              placeholder="Tipo de Vínculo"
              onChange={e => handleChangeGrauParentesco(e)}
            />

            <Input placeholder="Nome completo" name="name" icon={FiUser} />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              required
            />
            <Input
              icon={BiCake}
              name="birthdate"
              mask="date"
              maxLength={10}
              placeholder="Data de nascimento"
            />
          </Form>

          <Button
            type="button"
            width="small"
            fontSize="small"
            onClick={handleAddNovo}
          >
            Adicionar mais um beneficiário
          </Button>
        </Content>

        {participants.length > 0 ? (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="green"
              onClick={() => history.push('/participants-list')}
            >
              <span>Continuar</span>
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="green"
              onClick={handleJustSave}
            >
              <span>Continuar</span>
            </Button>
            <Button
              type="button"
              fontSize="normal"
              color="pink"
              onClick={() => history.push('/address-infos')}
            >
              <span>Não tenho beneficiários</span>
            </Button>
          </>
        )}

        <BtnVoltar type="button" onClick={() => history.push('/fatca')}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
        </ModalBox>
      </Container>
    </>
  )
}

export default NovoParticipante
