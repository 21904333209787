import styled from 'styled-components'
import { transparentize } from 'polished'

interface GoalItemProps {
  isSelected: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 868px;
  padding: 0 5px;


  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 0 25px 20px 25px;
  margin-bottom: 10px;
  text-align: center;

  .app_title {
    display: flex;
    width: 100%;
    justify-items: center;

    strong {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: ${props => props.theme.colors.text};

      display: flex;
      justify-content: center;
      margin: 0 0 15px 22.5px;
    }

    svg {
      width: 22.5px;
      height: 22.5px;
      color: #005F27;
      cursor: pointer;
    }
  }
  > strong {
    text-align: center;
    font-size: 18px;
    color: #FF612E;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  /* span {
    margin-bottom: 15px;
  } */

  small {
    margin-top: 15px;
  }

  footer {
    font-size: 11px;
  }

  iframe {
    width: 100%;
    height: 554px;
    outline: none;
    border: none;
    margin: 20px 0;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const TaxationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-evenly;

  width: 100%;
  margin: 20px 0;
`

export const GoalItem = styled.button<GoalItemProps>`
  width: 140px;
  height: 60px;

  background: transparent;
  border-radius: 4px;
  border: 1px solid ${props => props.isSelected
    ? transparentize(0.4, props.theme.colors.mainColor)
    : transparentize( 0.4, '#AEAEAE')};

  font-weight: ${props => props.isSelected
    ? 'bold'
    : 'normal'};
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 80%;
  height: 1px;
  margin: 15px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`


export const QuizContainer = styled.div`
  width: 100%;
  padding: 5px;
`

export const QuizContent = styled.div`

  .title {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.text};
  }

  .result {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    max-width: 700px;
    height: auto;
    text-align: center;
  }
`

export const Question = styled.p`
  text-align: left;
  font-weight: 700;
  font-size: 14px;
`

export const Form = styled.div`
  height: 350px;
  padding: 1.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: solid 1px #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`

export const Option = styled.label`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 5px 0;
  font-size: 12px;
  cursor: pointer;
`

export const CheckBox = styled.input`
  margin-right: 6px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BackButton = styled.button`
  font-size: 13px;
  font-weight: 700;
  height: 2.2rem;
  padding: 0 1.2rem;
  border-radius: 20px;
  border: solid 1px ${({ theme }) => theme.colors.text};
  background-color: unset;
  color: ${({ theme }) => theme.colors.text};
  transition: 0.3s;
  
  &:hover {
    transform: scale(0.93);
  }
`

export const NextButton = styled.button`
  font-size: 13px;
  font-weight: 700;
  height: 2.2rem;
  padding: 0 1.2rem;
  margin-left: auto;
  border: none;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.mainColor};
  color: #fff;

  transition: 0.3s;
  
  &:hover {
    transform: scale(0.93);
  }
`
