/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { FiCheck, FiX } from 'react-icons/fi'
import { RiQuestionLine } from 'react-icons/ri'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  RadioButton,
  BtnVoltar,
  BtnContato,
  CheckBox,
} from './styles'
import { UserDetails } from '../../utils/interfaces'
import Modal from '../../components/Modal'
import { FormSteps } from '../../components/FormSteps'

const FatcaInfo: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [ppe, setPPE] = useState('N')
  const [checkFatca1, setCheckFatca1] = useState(false)
  const [checkFatca2, setCheckFatca2] = useState(false)
  const [checkFatca3, setCheckFatca3] = useState(false)
  const [checkFatca4, setCheckFatca4] = useState(false)
  const [checkFatca5, setCheckFatca5] = useState(false)
  const [checkFatca6, setCheckFatca6] = useState(false)
  const [checkFatca7, setCheckFatca7] = useState(false)
  const [checkFatca8, setCheckFatca8] = useState(false)
  const [checkFatca9, setCheckFatca9] = useState(false)
  const [checkFatca10, setCheckFatca10] = useState(false)
  const [checkFatcaR] = useState(false)
  const [checkFatcaN, setCheckFatcaN] = useState(true)

  const [warningText, setWarningText] = useState(<></>)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    const usperson =
      (checkFatcaR || checkFatcaN) &&
      (!checkFatca1 ||
        !checkFatca2 ||
        !checkFatca3 ||
        !checkFatca4 ||
        !checkFatca5 ||
        !checkFatca6 ||
        !checkFatca7 ||
        !checkFatca8 ||
        !checkFatca9 ||
        !checkFatca10)
        ? 'N'
        : 'S'

    await setUserDetails({
      ...userDetails,
      ppe,
      usperson,
    })

    // history.push('/taxation')
    history.push('/new-participant')
  }, [
    checkFatcaR,
    checkFatcaN,
    checkFatca1,
    checkFatca2,
    checkFatca3,
    checkFatca4,
    checkFatca5,
    checkFatca6,
    checkFatca7,
    checkFatca8,
    checkFatca9,
    checkFatca10,
    setUserDetails,
    userDetails,
    ppe,
    history,
  ])

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const handleTextInfo = useCallback(() => {
    setWarningText(
      <>
        <FiX onClick={handleCloseModal} />
        <div>
          <strong>PESSOA POLITICAMENTE EXPOSTA</strong>
          <p>
            Considera-se pessoa politicamente exposta:
            <ul>
              <li>
                pessoa politicamente exposta aquela que desempenha ou tenha
                desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou
                funções públicas relevantes, no Brasil ou em outros países,
                territórios e dependências estrangeiros, assim como seus
                representantes, familiares e outras pessoas de seu
                relacionamento próximo.
              </li>
              <li>
                II — cargo, emprego ou função pública relevante exercido por
                chefes de estado e de governo, políticos de alto nível, altos
                servidores dos poderes públicos, magistrados ou militares de
                alto nível, dirigentes de empresas públicas ou dirigentes de
                partidos políticos; e
              </li>
              <li>
                III — familiares da pessoa politicamente exposta, seus parentes,
                na linha direta, até o primeiro grau, assim como o cônjuge,
                companheiro e enteado.
              </li>
            </ul>
          </p>
        </div>
      </>,
    )
    setIsModalOpen(true)
  }, [])
  return (
    <>
      <Header />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <FormSteps currentStep={2} />
            <div className="app_title">
              <strong>Pessoa politicamente exposta (PPE)?</strong>
              <RiQuestionLine onClick={() => handleTextInfo()} />
            </div>
            <small>
              De acordo com a instrução MPS/SPC nº 26 de 01/09/2008, Pessoa
              Politicamente Exposta é aquela que exerce ou exerceu nos últimos 5
              anos, no Brasil ou no exterior, cargo, emprego ou função pública
              relevante. Também é considerada aquela que tenha representantes,
              familiares e outras pessoas de seu relacionamento próximo que
              exercem ou tenham exercido nos últimos 5 anos, no Brasil ou no
              exterior, cargo, emprego ou função pública relevante.
            </small>
            <RadioButton>
              <div>
                <BtnContato
                  type="button"
                  isActive={ppe === 'S'}
                  onClick={() => setPPE('S')}
                >
                  Sim
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={ppe === 'N'}
                  onClick={() => setPPE('N')}
                >
                  Não
                </BtnContato>
              </div>
            </RadioButton>
          </Content>
          <Content>
            <strong>E-Financeira / FATCA</strong>
            <small>
              O preenchimento destas informações visa atender à Lei norte
              americana FATCA - Foreing Account Tax Compliance Act (Ato de
              conformidade Fiscal de Contas Estrangeiras), previsto no decreto
              brasileiro nº 8.506 de 24 de agosto de 2015, que tem como objetivo
              identificar contribuintes do imposto norte americano a fim de
              combater a evasão fiscal dos Estados Unidos.
            </small>
            <CheckBox>
              <div>
                <input
                  type="checkbox"
                  name="Check1"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca1 === true}
                  onChange={e => setCheckFatca1(e.target.checked)}
                />
                <label onClick={() => setCheckFatca1(!checkFatca1)}>
                  Possuo outra nacionalidade ou outra cidadania além da
                  brasileira.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check2"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca2 === true}
                  onChange={e => setCheckFatca2(e.target.checked)}
                />
                <label onClick={() => setCheckFatca2(!checkFatca2)}>
                  Possuo endereço, telefone ou caixa postal nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check3"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca3 === true}
                  onChange={e => setCheckFatca3(e.target.checked)}
                />
                <label onClick={() => setCheckFatca3(!checkFatca3)}>
                  Possuo imóvel nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check4"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca4 === true}
                  onChange={e => setCheckFatca4(e.target.checked)}
                />
                <label onClick={() => setCheckFatca4(!checkFatca4)}>
                  Possuo visto de residência permanente nos EUA (Green Card).
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check5"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca5 === true}
                  onChange={e => setCheckFatca5(e.target.checked)}
                />
                <label onClick={() => setCheckFatca5(!checkFatca5)}>
                  Permaneci por mais de 30 dias nos EUA no último ano (exceto
                  para fins de estudos).
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check6"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca6 === true}
                  onChange={e => setCheckFatca6(e.target.checked)}
                />
                <label onClick={() => setCheckFatca6(!checkFatca6)}>
                  Permaneci por mais de 183 dias nos EUA nos últimos 03 (três)
                  anos (exceto para fins de estudos).
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check7"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca7 === true}
                  onChange={e => setCheckFatca7(e.target.checked)}
                />
                <label onClick={() => setCheckFatca7(!checkFatca7)}>
                  Possuo participação societária (mais de 10%) em alguma empresa
                  nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check8"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca8 === true}
                  onChange={e => setCheckFatca8(e.target.checked)}
                />
                <label onClick={() => setCheckFatca8(!checkFatca8)}>
                  Possuo conta em instituição financeira nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check9"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca9 === true}
                  onChange={e => setCheckFatca9(e.target.checked)}
                />
                <label onClick={() => setCheckFatca9(!checkFatca9)}>
                  Possuo fonte de renda nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check10"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca10 === true}
                  onChange={e => setCheckFatca10(e.target.checked)}
                />
                <label onClick={() => setCheckFatca10(!checkFatca10)}>
                  Possuo residência fiscal, bens, ou qualquer valor tributário
                  em outro país.
                </label>
              </div>
              <div className="checkboxNo">
                <input
                  type="checkbox"
                  name="CheckN"
                  checked={checkFatcaN}
                  onChange={e => setCheckFatcaN(e.target.checked)}
                />
                <label onClick={() => setCheckFatcaN(!checkFatcaN)}>
                  NÃO, nenhuma das alternativas anteriores.
                </label>
              </div>
            </CheckBox>
          </Content>
        </Form>

        <Button
          type="button"
          fontSize="normal"
          color="green"
          width="large"
          onClick={handleSubmit}
          disabled={
            ppe === '' ||
            (!checkFatca1 &&
              !checkFatca2 &&
              !checkFatca3 &&
              !checkFatca4 &&
              !checkFatca5 &&
              !checkFatca6 &&
              !checkFatca7 &&
              !checkFatca8 &&
              !checkFatca9 &&
              !checkFatca10 &&
              !checkFatcaR &&
              !checkFatcaN)
          }
        >
          <FiCheck size={45} />
          <span>Pronto! Concluir</span>
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/investor')}>
          &lt; Anterior
        </BtnVoltar>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {warningText}
        </Modal>
      </Container>
    </>
  )
}

export default FatcaInfo
