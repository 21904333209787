import styled from 'styled-components'
import ColoredBox from '../../components/ColoredBox'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  min-height: 300px;
  padding: 30px 25px 40px;
  margin-bottom: 10px;
  text-align: center;

  > strong {
    text-align: center;
    font-size: 18px;
    color: ${props => props.theme.colors.text};

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  span {
    margin-bottom: 15px;
  }

  small {
    margin-top: 12px;
    width: 90%;
  }

  div.values-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 10px 0 25px;
    @media screen and (max-width: 500px) {
      display: contents;
    }
  }

  @media screen and (max-width: 766px) {
    padding: 30px 15px;
  }
`

export const InfoValuesBox = styled(ColoredBox)`
  width: fit-content;
  height: 300px;

  padding: 10px;
  margin: 10px;
  position: relative;
  text-align: left;

  span {
    font-weight: bold;
    font-size: 14px;
  }

  h3 {
    font-size: 18px;
    margin: 10px 0;

  }

  small {
    display: inline-block;
    font-size: 11px;
    line-height: 13px;
    padding: 3px 0;
    bottom: 0;
    margin-top: 8px;

    & + small {
      margin-top: 0;
    }

  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    height: auto;
    padding: 8px;
    margin: 8px;

    span {
      font-size: 14px;
    }

    h3 {
      font-size: 16px;
    }
  }
`
