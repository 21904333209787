import styled from 'styled-components'

interface BtnProps {
  isSelected?: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  .app_title {
    display: flex;
    width: 100%;
    justify-items: center;

    strong {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: ${props => props.theme.colors.text};

      display: flex;
      justify-content: center;
      margin: 0 0 15px 22.5px;
    }

    svg {
      width: 22.5px;
      height: 22.5px;
      color: #005F27;
      cursor: pointer;
    }
  }

  > form {
    width: 100%;
  }

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 0 25px 20px 25px;
  margin-bottom: 10px;
  text-align: center;

  > strong {
    text-align: center;
    font-size: 18px;
    color: ${props => props.theme.colors.text};

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > p {
    margin-bottom: 15px;
  }

  div.value {
    border: 1px solid #D9DADD;
    border-radius: 4px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin: 15px auto;
    padding: 15px 0;

    strong {
      font-size: 16px;
    }
  }

  > div.rules {
    margin: 12px auto 0;
    text-align: center;
    width: 75%;
    font-style: italic;
  }

  .collapse {
    width: 100%;

    div.contrib-sup {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0;

      p {
        width: 60%;
      }

      strong, svg {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > div.rules {
      width: 90%;
    }

    div.value {
      width: 70%;
      flex-direction: column;
    }
  }
`

export const URContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const URBox = styled.div`
  display: initial;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: auto;
  padding: 16px;
  margin: 2px 0;

  border: 1px solid #D9DADD;
  border-radius: 4px;

  div.UR-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 150px;

    p {
      text-align: left;
    }

    small {
      font-size: 11px;
      color: ${props => props.theme.colors.placeholder};
      text-align: left;
    }
  }

  > strong {
    margin: 0;
    justify-content: right;
    position: relative;
    width: 140px;
  }

  > div {
    height: 100%;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > strong {
      font-size: 14px;
      color: #333333;
      margin: 0;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-content: center;

    padding: 5px;
    height: 100%;

    div.UR-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 5px;

      small {
        font-size: 11px;
        color: ${props => props.theme.colors.placeholder};
      }
    }

    > strong {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
`

export const ButtonController = styled.button<BtnProps>`
  border-radius: 50%;
  border: none;
  background-color: transparent;
  width: 35px;
  height: 35px;
  margin-top: 5px;

  > svg {
    color: ${props => props.isSelected ? '#333' : '#D2D2D2'};
    font-size: 16px;
  }
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 15px auto;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-top: 8px;

    strong {
      font-size: 16px;
      color: ${props => props.theme.colors.mainColor};
    }

    small {
      font-size: 12px;
      display: flex;
    }
  }

  span {
    font-size: 11px;
    color: ${props => props.theme.colors.placeholder};
    font-style: italic;
    margin-top: 20px;

    & + span {
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 600px) {
    p {
      text-align: left;
    }

    > div {
      margin-top: 8px;

      small {
        text-align: left;
      }
    }
  }
`

export const NoContribCheck = styled.div`
  > div {
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: center;
    align-content: center;
    flex-direction: row;

    input[type=checkbox] {
      height: 14px;
      width: 14px;
      margin-right: 5px;
    }
    > label {
      color: black;
      margin-left: 5px;
      font-size: 16px;
      text-align: center;
    }
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
