/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Base64 } from 'js-base64'
import Switch from 'react-switch'

import { CircularProgress } from '@material-ui/core'
import { FiCheck, FiClock } from 'react-icons/fi'

import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'

import ModalBox from '../../components/Modal'
import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content, BtnVoltar, Confirmation } from './styles'
import {
  ConfigData,
  UserData,
  UserDetails,
  Participant,
  ErroProps,
  UserAddress,
} from '../../utils/interfaces'

const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userAddress, setUserAddress] = usePersistedState<UserAddress>(
    'userAddress',
    {} as UserAddress,
  )
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)

  const [acceptTerms, setAcceptTerms] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  const [flagPlano] = usePersistedState<number | null>('flagPlano', null)
  function handleCloseModal(): void {
    setIsModalOpen(false)
  }
  let dcrPlano = ''
  let linkRegulamento = ''
  let ctb1 = ''
  let ctb2 = ''
  let ctb3 = ''
  let ctbSup = ''
  let pct1 = ''
  let pct2 = '' // pctCtbEsporadicaPPR
  let pct3 = '' // pctCtbEsporadicaDecTerceiro
  let pctSup = ''
  if (flagPlano === 1) {
    dcrPlano = 'Plano de Aposentadoria Cargill'
    linkRegulamento =
      'https://cargillprev.com.br/wp-content/uploads/2023/01/REGULAMENTO-Plano-Aposentadoria_PREV-23.pdf'
    ctb1 = userDetails.contribuicaoBasicaF1
      .toFixed(2)
      .toString()
      .replace('.', ',')
    ctb2 = userDetails.contribuicaoBasicaF2
      .toFixed(2)
      .toString()
      .replace('.', ',')
    ctb3 = userDetails.contribuicaoBasicaF3
      .toFixed(2)
      .toString()
      .replace('.', ',')
    ctbSup = userDetails.contribuicaoSuplementar
      .toFixed(2)
      .toString()
      .replace('.', ',')
    pct1 = userDetails.pctContribuicaoBasicaF1.toString().replace('.', ',')
    pct2 = userDetails.pctContribuicaoBasicaF2.toString().replace('.', ',')
    pct3 = userDetails.pctContribuicaoBasicaF3.toString().replace('.', ',')
    pctSup = userDetails.pctContribuicaoSuplementar.toString().replace('.', ',')
  } else if (flagPlano === 5) {
    dcrPlano = 'Plano de Benefícios Cargill'
    linkRegulamento =
      'https://cargillprev.com.br/wp-content/uploads/2024/03/REGULAMENTO-Plano-Beneficios_PREV-24.pdf'
    ctb1 = userDetails.contribuicaoBasica
      .toFixed(2)
      .toString()
      .replace('.', ',')
    ctbSup = userDetails.contribuicaoSuplementar
      .toFixed(2)
      .toString()
      .replace('.', ',')
    pct1 = userDetails.pctContribuicaoBasica.toString().replace('.', ',')
    pctSup = userDetails.pctContribuicaoSuplementar.toString().replace('.', ',')
  } else if (flagPlano === 4) {
    dcrPlano = 'Plano Mais Vida'
    linkRegulamento =
      'https://cargillprev.com.br/wp-content/uploads/2023/03/REGULAMENTO-Plano-Mais-Vida_PREV-23.pdf'
    ctb1 = userDetails.contribuicaoBasica
      .toFixed(2)
      .toString()
      .replace('.', ',')
    ctbSup = userDetails.contribuicaoSuplementar
      .toFixed(2)
      .toString()
      .replace('.', ',')
    pct1 = userDetails.ucsTitular.toString().replace('.', ',')
    pctSup = userDetails.ucsConjuge.toString().replace('.', ',')
  }

  const history = useHistory()

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)
    const parametros = Base64.encode(
      `{
        "token": "${configData.token}",
        "versao": "${configData.tipo}",
        "plano": "${configData.plano}",
        "nome": "${userData.name}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "nascimento": "${userData.birthdate}",
        "email": "${userData.email}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "salario": "${userDetails.salario
          .toFixed(2)
          .toString()
          .replace('.', ',')}",
        "valor": "${ctb1}",
        "pctCtbBasica": "${pct1}",
        "valorsuplementar": "${ctbSup}",
        "pctCtbSuplementar": "${pctSup}",
        "pctCtbEsporadicaPPR":"${pct2}",
        "pctCtbEsporadicaDecTerceiro":"${pct3}",
        "usperson":"${userDetails.usperson}",
        "cicloVida":"${userDetails.lifeCicle}",
        "politicamenteexposto":"${userDetails.ppe}",
        "perfilInvest":"${userDetails.investor}",
        "endereco": [{
          "cep":"${userAddress.cep.replace('-', '')}",
          "logradouro":"${userAddress.logradouro.toLowerCase()}",
          "bairro":"${userAddress.bairro.toLowerCase()}",
          "municipio":"${userAddress.municipio.toLowerCase()}",
          "numero":"${userAddress.numero}",
          "complemento":"${userAddress.complemento.toLowerCase()}",
          "uf":"${userAddress.uf}"
        }],
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name}",
          "cpf": "${participant.data.cpf.replaceAll('.', '').replace('-', '')}",
          "nascimento": "${participant.data.birthdate}",
          "parentesco":"${
            participant.details.grauParentesco === undefined
              ? '0'
              : participant.details.grauParentesco
          }",
          "tipo":"${participant.details.tipoBen}"}`,
        )}]
        }`,
    )

    const parametrosFinal = Base64.encode(parametros)

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          alert('Tempo de sessão expirado')
          history.push('/register')
        } else if (res.message === 'Request failed with status code 406') {
          setWarningText(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          alert('Tempo de sessão expirado')
          history.push('/')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })
  }, [
    configData,
    ctb1,
    ctbSup,
    history,
    participants,
    pct1,
    pct2,
    pct3,
    pctSup,
    setErroProps,
    userAddress,
    userData,
    userDetails,
  ])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Confirmation>
            <p>
              Eu, {userData.name}, inscrito no CPF <b>{userData.cpf}</b>,
              declaro que ao clicar em <b>Confirmar Adesão</b> estou confirmando
              minha adesão ao <b>{dcrPlano}</b> e que as informações prestadas
              são verídicas.
            </p>

            <div>
              <small>
                Aceito os{' '}
                <a
                  href={linkRegulamento}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termos de Uso
                </a>
              </small>
              <Switch
                onChange={toggleAcceptTerms}
                checked={acceptTerms}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
                offColor="#DEE3E1"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>
          </Confirmation>
        </Content>

        <Button
          type="button"
          color="green"
          width="large"
          disabled={!acceptTerms || !reSend || aguarde}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default Conclusion
