export default {
  title: 'light',
  colors: {
    primary: '#00AF62',
    secondary: '#007A3B',
    tertiary: '#005F27',

    background: '#F7F8FE',
    backgroundSecondary: '#EAEBEE',
    backgroundTertiary: '#D9DADD',

    placeholder: '#666360',
    text: '#333',
    textSecondary: '#F5F5F5',

    golden: '#B67102',

    mainColor: '#005F27',
    secondaryColor: '#20E584',

    error: '#C53030',
    errorText: '#fddede',

    success: '#28A745',
    sucessText: '#CAFFD5',
  },
}
