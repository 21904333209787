import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { RiQuestionLine } from 'react-icons/ri'
import { FiX } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'
import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  GoalItem,
  BtnVoltar,
  TaxationBox,
  Line,
} from './styles'
import { UserDetails, Participant } from '../../utils/interfaces'

import tabelaIR from '../../assets/tabelaIR.jpg'
import Modal from '../../components/Modal'
import { FormSteps } from '../../components/FormSteps'

const InvestorProfile: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [selected, setSelected] = useState(userDetails.lifeCicle)

  const [isModalOpen, setIsModalOpen] = useState(false)

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const arr = participants.map(participant => participant.details.proporcao)

  const history = useHistory()

  const today = new Date()
  const dataAdesao = new Date(
    today.getFullYear(),
    today.getMonth() + 2,
    0,
  ).toLocaleDateString()

  const [descricTrib, setDescricTrib] = useState(
    <p>Selecione uma das opções para ver mais</p>,
  )

  const handleGoalItem = useCallback(valor => {
    if (valor === 'N') {
      setSelected(valor)
      setDescricTrib(
        <p>
          Você responderá a um questionário a seguir para descobrir em qual
          perfil você mais se enquadra.
        </p>,
      )
    } else if (valor === 'S') {
      setSelected(valor)
      setDescricTrib(
        <>
          <p>Seu perfil de investidor será baseado na sua idade.</p>
          <br />
          <p>
            Até 34 anos – Arrojado;
            <br />
            De 35 a 44 anos – Moderado;
            <br />
            De 45 a 54 anos – Conservador;
            <br />A partir de 55 anos – Super Conservador.
          </p>
        </>,
      )
    } else {
      setDescricTrib(<p>Selecione uma das opções para ver mais</p>)
    }
  }, [])

  useEffect(() => {
    handleGoalItem(userDetails.lifeCicle)
  }, [])

  const handleContinue = useCallback(async () => {
    if (selected === 'S') {
      if (userDetails.age <= 34) {
        await setUserDetails({
          ...userDetails,
          investor: 'ARJ',
          lifeCicle: selected,
        })
      } else if (userDetails.age > 34 && userDetails.age <= 44) {
        await setUserDetails({
          ...userDetails,
          investor: 'MOD',
          lifeCicle: selected,
        })
      } else if (userDetails.age > 44 && userDetails.age <= 54) {
        await setUserDetails({
          ...userDetails,
          investor: 'CSV',
          lifeCicle: selected,
        })
      } else if (userDetails.age > 54) {
        await setUserDetails({
          ...userDetails,
          investor: 'SCSV',
          lifeCicle: selected,
        })
      }
      history.push('/fatca')
    } else {
      await setUserDetails({
        ...userDetails,
        lifeCicle: selected,
      })
      history.push('/investor')
    }
  }, [history, selected, setUserDetails, userDetails])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormSteps currentStep={1} />
          <div className="app_title">
            <strong>Perfil de Investidor</strong>
            <RiQuestionLine onClick={() => setIsModalOpen(true)} />
          </div>
          <p>
            Escolha entre os modelos BASEADO EM RISCO (definido entre os perfis
            Super Conservador, Conservador, Moderado, Arrojado ou Super
            Arrojado) OU no CICLO DE VIDA (adequado automaticamente de acordo
            com a idade e consequente estimativa de concessão do benefício de
            aposentadoria)
          </p>
          <TaxationBox>
            <GoalItem
              key="1"
              isSelected={selected === 'N'}
              onClick={() => handleGoalItem('N')}
            >
              <span>Baseado em Risco</span>
            </GoalItem>
            <GoalItem
              key="2"
              isSelected={selected === 'S'}
              onClick={() => handleGoalItem('S')}
            >
              <span>Ciclo de Vida</span>
            </GoalItem>
          </TaxationBox>

          <Line />
          <footer>{descricTrib}</footer>
        </Content>

        <Button
          type="button"
          color="green"
          onClick={handleContinue}
          disabled={!selected}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('investor-quiz')}>
          &lt; Anterior
        </BtnVoltar>

        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <FiX onClick={handleCloseModal} />
          <div>
            <strong>PERFIL DE INVESTIMENTO</strong>
            <p>
              Está é uma decisão muito importante, afinal, é você quem decidirá
              como as suas contribuições serão investidas.
            </p>
            <p>
              Os perfis oferecidos são: Renda Fixa, Renda Variável Fundo Passivo
              e Renda Variável Fundo Ativo.
            </p>
            <p>
              Veja a seguir os detalhes de cada perfil de investimento:
              <ul>
                <li>
                  Perfil Renda Fixa: O objetivo do Perfil Renda Fixa é obter uma
                  rentabilidade mais estável, com baixo risco, por isso as
                  aplicações são realizadas em investimentos que tendem a
                  oferecer uma rentabilidade menor do que a rentabilidade dos
                  demais perfis em períodos mais longos, os ganhos são
                  proporcionais aos riscos. O participante pode aplicar até 100%
                  de suas reservas neste Fundo
                </li>
                <li>
                  Perfil Renda Variável: O objetivo deste Perfil é obter uma
                  rentabilidade superior ao Perfil Renda Fixa em períodos mais
                  longos, porém os recursos aplicados estão mais vulneráveis aos
                  riscos do mercado financeiro e sua volatilidade nos retornos.
                  O participante pode compor a aplicação de suas reservas em
                  Renda Variável até o limite de 35%, distribuídas entre os
                  Fundos Passivo e/ou Ativo, sendo permitida a alocação em
                  ambos:
                </li>
                <li>
                  Gestão Passiva: Esta opção é para quem busca rendimentos que
                  acompanhem a variação do Índice da Bolsa de Valores de São
                  Paulo, estando sujeito a impactos econômicos pouco previsíveis
                  no curto prazo.
                </li>
                <li>
                  Gestão Ativa: Esta opção é para quem busca rendimentos que
                  estejam descorrelacionados à variação do Índice da Bolsa de
                  Valores de São Paulo, com aplicações de recursos provenientes
                  exclusivamente de pessoas físicas e jurídicas em geral, cuja
                  seleção e gestão é realizada pelo time de especialistas do
                  Fundo.
                </li>
              </ul>
            </p>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default InvestorProfile
