/* eslint-disable react/jsx-wrap-multilines */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'

import { FiAlertCircle } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'

import InputSMSCode from '../../components/InputSMSCode'
import ModalBox from '../../components/Modal'
import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  ConfigData,
  UserData,
  ErroProps,
  UserDetails,
} from '../../utils/interfaces'
import { Container, Content, Timer, LinkBack, AlertContent } from './styles'
import calculaIdade from '../../utils/calculaIdade'

const ConfirmSMS: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [, setTipoPlano] = usePersistedState<string>('tipoPlano', 'N')

  const [warningText, setWarningText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reSend, setReSend] = useState(false)
  const [open, setOpen] = useState(false)
  const [sizeCode] = useState(4)
  const [timer, setTimer] = useState(60)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const contato = configData.tipoContato === 'S' ? 'SMS' : 'e-mail'

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const handleSubmit = useCallback(
    async data => {
      if (data.codeSMS.length === sizeCode) {
        const parametros = Base64.encode(
          `{"token": "${configData.token}",
          "codigoValidador": "${data.codeSMS}",
          "cpf":"${userData.cpf.replaceAll('.', '').replace('-', '')}",
          "cliente": "${configData.codCliente}"}`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .post(`wsAutenticacaoV2.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setUserDetails({
              ...userDetails,
              salario: res.data.salario === null ? 0 : res.data.salario,
              age: calculaIdade(userData.birthdate),
            })
            setTipoPlano(res.data.tipoPlano)
            history.push('/welcome')
          })
          .catch(err => setOpen(true))
      }
    },
    [
      configData,
      history,
      setTipoPlano,
      setUserDetails,
      sizeCode,
      userData,
      userDetails,
    ],
  )

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }

    const interval = setInterval(countTimer, 1000)

    return () => clearInterval(interval)
  }, [timer])

  const handleReSend = useCallback(async () => {
    const parametrosGet = Base64.encode(
      `{"versao":"${configData.tipo}",
        "cliente":"${configData.codCliente}",
        "cpf":"${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "email":"${userData.email === undefined ? '' : userData.email}",
        "telefone":"${
          userData.phone === undefined
            ? ''
            : `55${userData.phone
                .replace('(', '')
                .replace(') ', '')
                .replace('-', '')}`
        }",
        "nascimento":"${userData.birthdate}",
        "envio":"${configData.tipoContato}"}`,
    )

    const parametrosFinalGet = Base64.encode(parametrosGet)

    await api
      .get(`wsAutenticacaoV2.rule?sys=ADZ&Entrada=${parametrosFinalGet}`)
      .then(res => setConfigData({ ...configData, token: res.data.token }))
      .catch(res => {
        if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else {
          setIsModalOpen(true)
          setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
        }
      })

    history.push('/register/confirm-sms')

    setReSend(false)
    setTimer(60)
  }, [configData, history, setConfigData, setErroProps, userData])

  return (
    <>
      <Header />

      <Container>
        <Content>
          <span>
            Digite aqui o código de verificação que acabamos de te enviar via{' '}
            {contato}
          </span>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputSMSCode name="codeSMS" size={sizeCode} formRef={formRef} />
          </Form>

          <Collapse in={open}>
            <AlertContent>
              <div>
                <FiAlertCircle />
                <p>Código incorreto!</p>
              </div>
              <button type="button" onClick={() => window.location.reload()}>
                Limpar
              </button>
            </AlertContent>
          </Collapse>

          <p>Não está recebendo o código? Sem problemas!</p>
          <Timer>00:{timer < 10 ? `0${timer}` : timer}</Timer>
          <Button
            onClick={handleReSend}
            type="button"
            color="green"
            disabled={!reSend}
          >
            Reenviar
          </Button>
          <p>
            Clique em <strong>reenviar</strong> para receber um novo código
          </p>
          <LinkBack to="/">Quero trocar a forma de contato</LinkBack>
        </Content>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default ConfirmSMS
