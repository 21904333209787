import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'

import { Collapse } from '@material-ui/core'
import { RiQuestionLine } from 'react-icons/ri'
import { FiX } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { UserDetails } from '../../utils/interfaces'
import {
  Container,
  Content,
  InvestorBox,
  GoalItem,
  BtnVoltar,
  Line,
} from './styles'
import ModalBox from '../../components/Modal'
import { FormSteps } from '../../components/FormSteps'

const Investor: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [selected, setSelected] = useState(userDetails.investor)
  const [selectedQuiz] = usePersistedState<string>('selectedQuiz', '')
  const [lifeCicle, setLifeCicle] = useState(
    userDetails.lifeCicle === undefined ? 'N' : userDetails.lifeCicle,
  )

  const [dcrSelected, setDcrSelected] = usePersistedState<string>(
    'dcrSelected',
    '',
  )
  const [dcrSelectedQuiz] = usePersistedState<string>('dcrSelectedQuiz', '')

  const history = useHistory()

  const [descrInvest, setDescInvest] = useState(<></>)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [textoModal, setTextoModal] = useState(<></>)
  const [acceptInvestor, setAcceptInvestor] = usePersistedState<boolean>(
    'acceptInvestor',
    false,
  )

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const handleInvestItem = useCallback(valor => {
    if (valor === 'SV') {
      setSelected(valor)
      setDescInvest(
        <>
          <p>Seu perfil de investidor será baseado na sua idade.</p>
          <br />
          <p>
            Até 34 anos – Arrojado;
            <br />
            De 35 a 44 anos – Moderado;
            <br />
            De 45 a 54 anos – Conservador;
            <br />A partir de 55 anos – Super Conservador.
          </p>
        </>,
      )
      setLifeCicle('S')
      setDcrSelected('ciclo de vida')
    } else if (valor === 'SCSV') {
      setLifeCicle('N')
      setSelected(valor)
      setDescInvest(<p>100% Renda Fixa (CDI).</p>)
      setDcrSelected('super conservador')
    } else if (valor === 'CSV') {
      setLifeCicle('N')
      setSelected(valor)
      setDescInvest(
        <p>
          70% Renda Fixa (CDI);
          <br />
          12,5% Renda Fixa (Crédito);
          <br />
          5% Renda Variável;
          <br />
          10% Estruturados;
          <br />
          2,5% Exterior.
        </p>,
      )
      setDcrSelected('conservador')
    } else if (valor === 'MOD') {
      setLifeCicle('N')
      setSelected(valor)
      setDescInvest(
        <p>
          44% Renda Fixa (CDI); <br />
          17,5% Renda Fixa (Crédito);
          <br />
          17,5% Renda Variável;
          <br />
          12,5% Estruturados;
          <br />
          8,5% Exterior.
        </p>,
      )
      setDcrSelected('moderado')
    } else if (valor === 'ARJ') {
      setLifeCicle('N')
      setSelected(valor)
      setDescInvest(
        <p>
          20% Renda Fixa (CDI);
          <br />
          20% Renda Fixa (Crédito);
          <br />
          35% Renda Variável;
          <br />
          15% Estruturados;
          <br />
          10% Exterior.
        </p>,
      )
      setDcrSelected('arrojado')
    } else if (valor === 'SARJ') {
      setLifeCicle('N')
      setSelected(valor)
      setDescInvest(
        <p>
          12% Renda Fixa (CDI);
          <br />
          15% Renda Fixa (Crédito);
          <br />
          50% Renda Variável;
          <br />
          14% Estruturados;
          <br />
          9% Exterior.
        </p>,
      )
      setDcrSelected('super arrojado')
    }
  }, [])

  useEffect(() => {
    handleInvestItem(lifeCicle === 'S' ? 'SV' : selected)
  }, [])

  const handleContinue = useCallback(async () => {
    if (selected === '') {
      setIsModalOpen(true)
      setTextoModal(<p>Informe seu perfil de investidor</p>)
    } else if (selected === 'SV') {
      if (userDetails.age <= 34) {
        await setUserDetails({
          ...userDetails,
          investor: 'ARJ',
          lifeCicle: 'S',
        })
      } else if (userDetails.age > 34 && userDetails.age <= 44) {
        await setUserDetails({
          ...userDetails,
          investor: 'MOD',
          lifeCicle: 'S',
        })
      } else if (userDetails.age > 44 && userDetails.age <= 54) {
        await setUserDetails({
          ...userDetails,
          investor: 'CSV',
          lifeCicle: 'S',
        })
      } else if (userDetails.age > 54) {
        await setUserDetails({
          ...userDetails,
          investor: 'SCSV',
          lifeCicle: 'S',
        })
      }
      history.push('/fatca')
    } else {
      await setUserDetails({
        ...userDetails,
        investor: selected,
        lifeCicle: 'N',
      })
      history.push('/fatca')
      //   participants.length > 0
      //     ? history.push('/participants-list')
      //     : history.push('/new-participant')
    }
  }, [history, selected, setUserDetails, userDetails])

  const toggleAcceptInvestor = useCallback(() => {
    setAcceptInvestor(!acceptInvestor)
  }, [acceptInvestor, setAcceptInvestor])

  const handleTextInfo = useCallback(() => {
    setTextoModal(
      <>
        <FiX onClick={handleCloseModal} />
        <div>
          <strong>RENDA FIXA</strong>
          <p>
            O objetivo do Perfil Renda Fixa é obter uma rentabilidade mais
            estável, com baixo risco, por isso as aplicações são realizadas em
            investimentos que tendem a oferecer uma rentabilidade menor do que a
            rentabilidade dos demais perfis em períodos mais longos, os ganhos
            são proporcionais aos riscos. O participante pode aplicar até 100%
            de suas reservas neste Fundo.
          </p>
          <Line />
          <strong>RENDA VARIÁVEL</strong>
          <p>
            O objetivo deste Perfil é obter uma rentabilidade superior ao Perfil
            Renda Fixa em períodos mais longos, porém os recursos aplicados
            estão mais vulneráveis aos riscos do mercado financeiro e sua
            volatilidade nos retornos. O participante pode compor a aplicação de
            suas reservas em Renda Variável até o limite de 35%, distribuídas
            entre os Fundos Passivo e/ou Ativo, sendo permitida a alocação em
            ambos:
            <ul>
              <li>
                Gestão Passiva: Esta opção é para quem busca rendimentos que
                acompanhem a variação do Índice da Bolsa de Valores de São
                Paulo, estando sujeito a impactos econômicos pouco previsíveis
                no curto prazo.
              </li>
              <li>
                Gestão Ativa: Esta opção é para quem busca rendimentos que
                estejam descorrelacionados à variação do Índice da Bolsa de
                Valores de São Paulo, com aplicações de recursos provenientes
                exclusivamente de pessoas físicas e jurídicas em geral, cuja
                seleção e gestão é realizada pelo time de especialistas do
                Fundo.
              </li>
            </ul>
          </p>
        </div>
      </>,
    )
    setIsModalOpen(true)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormSteps currentStep={1} />
          <div className="app_title">
            <strong>Perfil de Investimento</strong>
            <RiQuestionLine onClick={() => handleTextInfo()} />
          </div>
          <p>
            Escolha entre os modelos BASEADO EM RISCO (definido entre os perfis
            Super Conservador, Conservador, Moderado, Arrojado ou Super
            Arrojado) OU no CICLO DE VIDA (adequado automaticamente de acordo
            com a idade e consequente estimativa de concessão do benefício de
            aposentadoria)
          </p>

          <InvestorBox>
            <GoalItem
              key="0"
              isSelected={lifeCicle === 'S'}
              onClick={() => handleInvestItem('SV')}
            >
              <span>Ciclo de Vida</span>
            </GoalItem>
            <GoalItem
              key="1"
              isSelected={selected === 'SCSV' && lifeCicle !== 'S'}
              onClick={() => handleInvestItem('SCSV')}
            >
              <span>SUPER CONSERVADOR</span>
            </GoalItem>
            <GoalItem
              key="2"
              isSelected={selected === 'CSV' && lifeCicle !== 'S'}
              onClick={() => handleInvestItem('CSV')}
            >
              <span>CONSERVADOR</span>
            </GoalItem>
            <GoalItem
              key="3"
              isSelected={selected === 'MOD' && lifeCicle !== 'S'}
              onClick={() => handleInvestItem('MOD')}
            >
              <span>MODERADO</span>
            </GoalItem>
            <GoalItem
              key="4"
              isSelected={selected === 'ARJ' && lifeCicle !== 'S'}
              onClick={() => handleInvestItem('ARJ')}
            >
              <span>ARROJADO</span>
            </GoalItem>
            <GoalItem
              key="5"
              isSelected={selected === 'SARJ' && lifeCicle !== 'S'}
              onClick={() => handleInvestItem('SARJ')}
            >
              <span>SUPER ARROJADO</span>
            </GoalItem>
          </InvestorBox>

          <Line />
          <footer>{descrInvest}</footer>

          <Collapse in={selectedQuiz !== selected}>
            <Line />
            <div className="switch">
              <small>
                {`Estou ciente que o perfil de investimento escolhido (${dcrSelected}) difere do resultado do perfil de investidor demonstrado no questionário (${dcrSelectedQuiz}).`}
              </small>
              <Switch
                onChange={toggleAcceptInvestor}
                checked={acceptInvestor}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
                offColor="#DEE3E1"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>
          </Collapse>
        </Content>
        <Button
          type="button"
          color="green"
          onClick={handleContinue}
          disabled={!selected || (selectedQuiz !== selected && !acceptInvestor)}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/investor-quiz')}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {textoModal}
        </ModalBox>
      </Container>
    </>
  )
}

export default Investor
