import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import BemVindo from '../pages/BemVindo'
import Contribution from '../pages/Contribution'
import ContributionP1 from '../pages/ContributionP1'
import ContributionP2 from '../pages/ContributionP2'
import ContributionP3 from '../pages/ContributionP3'
import Simulation from '../pages/Simulation'
import InvestorProfile from '../pages/InvestorProfile'
import Benefit from '../pages/Benefit'
import Register from '../pages/Register'
import ConfirmSMS from '../pages/ConfirmSMS'
import Taxation from '../pages/Taxation'
import InvestorQuiz from '../pages/InvestorQuiz'
import Investor from '../pages/Investor'
import NovoParticipante from '../pages/NovoParticipante'
import ParticipantsList from '../pages/ParticipantsList'
import Fatca from '../pages/Fatca'
import Resume from '../pages/Resume'
import Conclusion from '../pages/Conclusion'
import End from '../pages/End'
import Erro from '../pages/Erro'
import NotFound from '../pages/NotFound'

import usePersistedState from '../hooks/usePersistedState'
import clientConfig from '../services/clientConfig'
import { ConfigData } from '../utils/interfaces'
import AddressInfos from '../pages/AddressInfos'

// import Login from '../pages/Login'
// import GoalSelection from '../pages/GoalSelection'
// import ConfirmOwnership from '../pages/ConfirmOwnership'
// import Help from '../pages/Help'
// import Terms from '../pages/Terms'
// import PersonalInfos from '../pages/PersonalInfos'

const Routes: React.FC = () => {
  const { codCliente, tipo, plano } = clientConfig()

  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [flagPlano] = usePersistedState<number | null>('flagPlano', null)

  useEffect(() => {
    setConfigData({
      ...configData,
      codCliente,
      tipo,
      plano: flagPlano,
    })
  }, [flagPlano])

  return (
    <Switch>
      <Route path="/" exact component={Register} />
      <Route path="/register/confirm-sms" component={ConfirmSMS} />
      <Route path="/welcome" exact component={BemVindo} />
      <Route path="/address-infos" exact component={AddressInfos} />
      <Route path="/contribution" component={Contribution} />

      <Route path="/contribution-p1" component={ContributionP1} />
      <Route path="/contribution-p2" component={ContributionP2} />
      <Route path="/contribution-p3" component={ContributionP3} />
      <Route path="/simulation" component={Simulation} />
      <Route path="/investor-profile" component={InvestorProfile} />
      <Route path="/benefit" component={Benefit} />

      {/* <Route path="/taxation" component={Taxation} /> */}
      <Route path="/investor-quiz" component={InvestorQuiz} />
      <Route path="/investor" component={Investor} />
      <Route path="/new-participant" component={NovoParticipante} />
      <Route path="/participants-list" component={ParticipantsList} />
      <Route path="/fatca" component={Fatca} />
      <Route path="/resume" component={Resume} />
      <Route path="/conclusion" component={Conclusion} />
      <Route path="/end" component={End} />
      <Route path="/erro" component={Erro} />
      <Route path="*" component={NotFound} />

      {/* <Route path="/goal-selection" component={GoalSelection} />
      <Route path="/personal-infos" component={PersonalInfos} />
      <Route path="/help" component={Help} />
      <Route path="/confirm-ownership" component={ConfirmOwnership} />
      <Route path="/terms" component={Terms} />
      <Route path="/login" component={Login} /> */}
    </Switch>
  )
}

export default Routes
