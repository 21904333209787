/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Collapse } from '@material-ui/core'
import { FiHome, FiMail, FiMap, FiMapPin, FiSmartphone, FiUser } from 'react-icons/fi'
import * as Yup from 'yup'
import { MdSecurity } from 'react-icons/md'
import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'
import Header from '../../components/Header'
import Button from '../../components/Button'
import { UserData, UserDetails, Participant, UserAddress } from '../../utils/interfaces'
import {
  Container,
  Content,
  BtnVoltar,
  Participants,
  InfoContent,
  Line,
  RadioButton,
  BtnOption,
  BtnModal,
} from './styles'
import ModalBox from '../../components/Modal'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'
import { formatValue } from '../../utils/formatValues'
import { FormSteps } from '../../components/FormSteps'
import { BiBuildings, BiMapPin } from 'react-icons/bi'
import { RiCommunityLine } from 'react-icons/ri'
import { AiOutlineFieldNumber } from 'react-icons/ai'

const Resume: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userAddress, setUserAddress] = usePersistedState<UserAddress>(
    'userAddress',
    {} as UserAddress,
  )

  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [, setFlagEdit] = usePersistedState<'S' | 'N' | ''>('flagEdit', 'N')
  const [flagPlano] = usePersistedState<number | null>('flagPlano', null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal] = useState('')

  const [selectedQuiz] = usePersistedState<string>('selectedQuiz', '')
  const [dcrSelected] = usePersistedState<string>('dcrSelected', '')
  const [dcrSelectedQuiz] = usePersistedState<string>('dcrSelectedQuiz', '')
  const [acceptInvestor, setAcceptInvestor] = usePersistedState<boolean>(
    'acceptInvestor',
    false,
  )

  const formRef = useRef<FormHandles>(null)

  let dcrInvestor = ''
  if (userDetails.investor === 'ARJ') {
    dcrInvestor = 'Arrojado'
  } else if (userDetails.investor === 'MOD') {
    dcrInvestor = 'Moderado'
  } else if (userDetails.investor === 'CSV') {
    dcrInvestor = 'Conservador'
  } else if (userDetails.investor === 'SCSV') {
    dcrInvestor = 'Super conservador'
  } else if (userDetails.investor === 'SARJ') {
    dcrInvestor = 'Super arrojado'
  }

  let vlrDesconto = 0
  if (flagPlano === 1) {
    vlrDesconto =
      userDetails.contribuicaoBasicaF1 +
      userDetails.contribuicaoBasicaF2 +
      userDetails.contribuicaoBasicaF3 +
      userDetails.contribuicaoSuplementar
  } else if (flagPlano === 5) {
    vlrDesconto =
      userDetails.contribuicaoBasica + userDetails.contribuicaoSuplementar
  } else if (flagPlano === 4) {
    vlrDesconto =
      userDetails.contribuicaoBasica + userDetails.contribuicaoSuplementar
  }

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }
  const history = useHistory()

  const handleConfirmaAdesao = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserData({
          ...userData,
          email: data.email,
          phone: data.phone,
        })

        setUserAddress({
          ...userAddress,
          numero: data.numero,
          complemento: data.complemento,
        })

        localStorage.removeItem('@CargillPrev:flagEdit')

        history.push('/conclusion')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [setUserData, userData, history],
  )

  const dependents = participants.filter(
    participant => participant.details.grauParentesco !== undefined,
  )

  const goback = useCallback(
    async e => {
      await setFlagEdit('S')

      if (e === 'S') {
        if (flagPlano === 1) {
          history.push('/contribution-p1')
        } else if (flagPlano === 5) {
          history.push('/contribution-p2')
        } else if (flagPlano === 4) {
          history.push('/contribution-p3')
        }
      } else if (e === 'P') {
        history.push('investor')
      } else {
        history.push('/new-participant')
      }
    },
    [flagPlano, history, setFlagEdit],
  )

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  const toggleAcceptInvestor = useCallback(() => {
    setAcceptInvestor(!acceptInvestor)
  }, [acceptInvestor, setAcceptInvestor])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormSteps currentStep={4} />
          <strong>Resumo de Adesão</strong>
          <Form
            onSubmit={handleConfirmaAdesao}
            ref={formRef}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              phone: userData.phone,
              email: userData.email,
              cep: userAddress.cep,
              logradouro: userAddress.logradouro,
              bairro: userAddress.bairro,
              municipio: userAddress.municipio,
              numero: userAddress.numero,
              complemento: userAddress.complemento,
              uf: userAddress.uf
            }}
          >
            <Participants>
              <h4>Dados pessoais</h4>
              <div className="titular">
                <Input
                  name="name"
                  placeholder="Nome"
                  sizeBox="small"
                  icon={FiUser}
                  disabled
                />
                <Input
                  placeholder="CPF"
                  name="cpf"
                  icon={MdSecurity}
                  type="tel"
                  mask="cpf"
                  sizeBox="small"
                  disabled
                />
                <Input
                  name="phone"
                  mask="phone"
                  placeholder="Celular com DDD"
                  icon={FiSmartphone}
                  sizeBox="small"
                />
                <Input
                  icon={FiMail}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  sizeBox="small"
                />
              </div>
            </Participants>

            <Line />

            <Participants>
              <h4>Endereço</h4>
              <div className="titular">
                <Input
                  name="cep"
                  placeholder="CEP"
                  mask='cep'
                  sizeBox="small"
                  icon={BiMapPin}
                  disabled
                />
                <Input
                  placeholder="Logradouro"
                  name="logradouro"
                  icon={FiHome}
                  sizeBox="small"
                  disabled
                />
                <Input
                  name="bairro"
                  placeholder="Bairro"
                  icon={RiCommunityLine}
                  sizeBox="small"
                  disabled
                />
                <Input
                  icon={BiBuildings}
                  name="municipio"
                  placeholder="Município"
                  sizeBox="small"
                  disabled
                />
                <Input
                  icon={AiOutlineFieldNumber}
                  name="numero"
                  placeholder="Número"
                  sizeBox="small"
                />
                <Input
                  icon={FiMapPin}
                  name="complemento"
                  placeholder="Complemento"
                  sizeBox="small"
                />
                <Input
                  icon={FiMap}
                  name="uf"
                  placeholder="UF"
                  sizeBox="small"
                  disabled
                />
              </div>
            </Participants>

            <Line />

            <Participants>
              <h4>Contribuição</h4>
              <InfoContent>
                <div>
                  <p>Desconto total:</p>
                  <strong>{formatValue(vlrDesconto)}</strong>
                </div>
                <button
                  className="edit"
                  type="button"
                  onClick={() => {
                    setFlagEdit('S')
                    goback('S')
                  }}
                >
                  Simular novamente
                </button>
              </InfoContent>
            </Participants>

            <Line />

            <Participants>
              <h4>Informações adicionais</h4>
              {userDetails.lifeCicle === 'N' ? (
                <RadioButton>
                  <label>Perfil de Investidor</label>
                  <div>
                    <BtnOption
                      type="button"
                      isActive={userDetails.investor === 'SCSV'}
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          investor: 'SCSV',
                        })
                      }}
                    >
                      Super conservador
                    </BtnOption>
                    <BtnOption
                      type="button"
                      isActive={userDetails.investor === 'CSV'}
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          investor: 'CSV',
                        })
                      }}
                    >
                      Conservador
                    </BtnOption>
                    <BtnOption
                      type="button"
                      isActive={userDetails.investor === 'MOD'}
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          investor: 'MOD',
                        })
                      }}
                    >
                      Moderado
                    </BtnOption>
                  </div>
                  <div>
                    <BtnOption
                      type="button"
                      isActive={userDetails.investor === 'ARJ'}
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          investor: 'ARJ',
                        })
                      }}
                    >
                      Arrojado
                    </BtnOption>
                    <BtnOption
                      type="button"
                      isActive={userDetails.investor === 'SARJ'}
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          investor: 'SARJ',
                        })
                      }}
                    >
                      Super arrojado
                    </BtnOption>
                  </div>
                  <Collapse in={selectedQuiz !== userDetails.investor}>
                    <Line />
                    <div className="switch">
                      <small>
                        {`Estou ciente que o perfil de investimento escolhido (${dcrInvestor}) difere do resultado do perfil de investidor demonstrado no questionário (${dcrSelectedQuiz}).`}
                      </small>
                      <Switch
                        onChange={toggleAcceptInvestor}
                        checked={acceptInvestor}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={20}
                        width={40}
                        handleDiameter={16}
                        offColor="#DEE3E1"
                        offHandleColor="#fff"
                        onHandleColor="#fff"
                        onColor="#31D19E"
                      />
                    </div>
                  </Collapse>
                </RadioButton>
              ) : (
                <InfoContent>
                  <label>Perfil de Investidor</label>
                  <div>
                    <p>Ciclo de vida</p>
                    <strong>{dcrInvestor}</strong>
                  </div>
                  <button
                    className="edit"
                    type="button"
                    onClick={() => {
                      setFlagEdit('S')
                      goback('P')
                    }}
                  >
                    Trocar
                  </button>
                </InfoContent>
              )}
              {/* <RadioButton>
                <label>Regime de Tributação</label>
                <div>
                  <BtnOption
                    type="button"
                    isActive={userDetails.tributacao === 'P'}
                    onClick={() => {
                      setUserDetails({
                        ...userDetails,
                        tributacao: 'P',
                      })
                    }}
                  >
                    Progressivo
                  </BtnOption>
                  {flagPlano === 4 ? (
                    <></>
                  ) : (
                    <BtnOption
                      type="button"
                      isActive={userDetails.tributacao === 'R'}
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          tributacao: 'R',
                        })
                      }}
                    >
                      Regressivo
                    </BtnOption>
                  )}
                </div>
              </RadioButton> */}

              <RadioButton>
                <label>Pessoa politicamente exposta (PPE)?</label>
                <div>
                  <BtnOption
                    type="button"
                    isActive={userDetails.ppe === 'S'}
                    onClick={() => {
                      setUserDetails({
                        ...userDetails,
                        ppe: 'S',
                      })
                    }}
                  >
                    Sim
                  </BtnOption>
                  <BtnOption
                    type="button"
                    isActive={userDetails.ppe === 'N'}
                    onClick={() => {
                      setUserDetails({
                        ...userDetails,
                        ppe: 'N',
                      })
                    }}
                  >
                    Não
                  </BtnOption>
                </div>
              </RadioButton>

              <RadioButton>
                <label>É residente no exterior para fins fiscais?</label>
                <div>
                  <BtnOption
                    type="button"
                    isActive={userDetails.usperson === 'S'}
                    onClick={() => {
                      setUserDetails({
                        ...userDetails,
                        usperson: 'S',
                      })
                    }}
                  >
                    Sim
                  </BtnOption>
                  <BtnOption
                    type="button"
                    isActive={userDetails.usperson === 'N'}
                    onClick={() => {
                      setUserDetails({
                        ...userDetails,
                        usperson: 'N',
                      })
                    }}
                  >
                    Não
                  </BtnOption>
                </div>
              </RadioButton>
            </Participants>

            <Participants>
              {participants.length > 0 ? (
                <>
                  <Line />
                  <h4>Beneficiários</h4>
                  <InfoContent>
                    {dependents.map(d => (
                      <div key={Math.random()} className="dependents">
                        <div className="infos">
                          <p>{d.data.name}</p>
                          <small>
                            {d.details.dcrGrauParentesco}
                            {', '}
                            {calculaIdade(d.data.birthdate)}{' '}
                            {calculaIdade(d.data.birthdate) > 1
                              ? 'anos'
                              : 'ano'}
                          </small>
                        </div>
                      </div>
                    ))}
                    <button
                      className="edit"
                      type="button"
                      onClick={() => goback('b')}
                    >
                      Incluir novo beneficiário
                    </button>
                  </InfoContent>
                </>
              ) : (
                <></>
              )}
            </Participants>
          </Form>
        </Content>
        <Button
          type="button"
          color="green"
          width="large"
          disabled={selectedQuiz !== userDetails.investor && !acceptInvestor}
          onClick={handleConfirmar}
        >
          <span>Confirmar informações</span>
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Resume
