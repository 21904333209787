/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import Collapse from '@material-ui/core/Collapse'
import { Stepper, Step } from 'react-form-stepper'

import { FiDollarSign, FiX } from 'react-icons/fi'
import { RiQuestionLine } from 'react-icons/ri'
import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  BtnVoltar,
  URContent,
  URBox,
  InfoContent,
  Line,
  NoContribCheck,
} from './styles'
import { UserDetails } from '../../utils/interfaces'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'
import { ValorMascarar } from '../../utils/masks'
import InputRange from '../../components/InputRange'
import Modal from '../../components/Modal'
import { FormSteps } from '../../components/FormSteps'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

const ContributionP1: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)

  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [vlrCtbBasicaF1, setVlrCtbBasicaF1] = useState(
    userDetails.contribuicaoBasicaF1 > 0 ? userDetails.contribuicaoBasicaF1 : 0,
  )
  const [vlrCtbBasicaF2, setVlrCtbBasicaF2] = useState(
    userDetails.contribuicaoBasicaF2 > 0 ? userDetails.contribuicaoBasicaF2 : 0,
  )
  const [vlrCtbBasicaF3, setVlrCtbBasicaF3] = useState(
    userDetails.contribuicaoBasicaF3 > 0 ? userDetails.contribuicaoBasicaF3 : 0,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [, setVlrCtbTotal] = useState(
    userDetails.contribuicaoBasica * 2 > 0
      ? userDetails.contribuicaoBasica * 2
      : 0,
  )
  const [pctCtbBasF1, setPctCtbBasF1] = useState(
    userDetails.pctContribuicaoBasicaF1 > 0
      ? userDetails.pctContribuicaoBasicaF1
      : 0,
  )
  const [pctCtbBasF2, setPctCtbBasF2] = useState(
    userDetails.pctContribuicaoBasicaF2 > 0
      ? userDetails.pctContribuicaoBasicaF2
      : 0,
  )
  const [pctCtbBasF3, setPctCtbBasF3] = useState(
    userDetails.pctContribuicaoBasicaF3 > 0
      ? userDetails.pctContribuicaoBasicaF3
      : 0,
  )

  const [pctCtbSup, setPctCtbSup] = useState(
    userDetails.pctContribuicaoSuplementar > 0
      ? userDetails.pctContribuicaoSuplementar
      : 0,
  )
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )

  const [flagEdit, setFlagEdit] = usePersistedState<'S' | 'N' | ''>(
    'flagEdit',
    'N',
  )

  const [SUA] = useState(353.43) // SUA - Salário Unitário Atualizado

  const [faixa1] = useState(SUA * 20)
  const [faixa2] = useState(SUA * 70)

  const [visibility, setVisibility] = useState(false)
  const [salarioValue, setSalarioValue] = useState(
    userDetails.salario > 0 ? userDetails.salario : 0,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  /* CALCULO DE PARCELAS/FAIXAS DE CONTRIBUIÇÃO */
  useEffect(() => {
    let vlrContribParticF1 = 0
    let vlrContribParticF2 = 0
    let vlrContribParticF3 = 0

    if (salarioValue <= faixa1) {
      vlrContribParticF1 = salarioValue * (pctCtbBasF1 / 100)
    } else if (salarioValue > faixa1 && salarioValue <= faixa2) {
      vlrContribParticF1 = faixa1 * (pctCtbBasF1 / 100)
      if (pctCtbBasF2 > 0) {
        vlrContribParticF2 = (salarioValue - faixa1) * (pctCtbBasF2 / 100)
      }
    } else if (salarioValue > faixa2) {
      vlrContribParticF1 = faixa1 * (pctCtbBasF1 / 100)
      if (pctCtbBasF2 > 0) {
        vlrContribParticF2 = (faixa2 - faixa1) * (pctCtbBasF2 / 100)
      }
      if (pctCtbBasF3 > 0) {
        vlrContribParticF3 = (salarioValue - faixa2) * (pctCtbBasF3 / 100)
      }
    }

    setVlrCtbBasicaF1(vlrContribParticF1)
    setVlrCtbBasicaF2(vlrContribParticF2)
    setVlrCtbBasicaF3(vlrContribParticF3)
    const vlrContribPatroc =
      vlrContribParticF1 + vlrContribParticF2 + vlrContribParticF3
    setVlrCtbPatrocinadora(vlrContribPatroc)
    setVlrCtbTotal(vlrContribParticF1 + vlrContribParticF2 + vlrContribParticF3)
  }, [
    salarioValue,
    pctCtbBasF1,
    SUA,
    vlrCtbBasicaF1,
    vlrCtbPatrocinadora,
    faixa1,
    faixa2,
    pctCtbBasF2,
    pctCtbBasF3,
  ])

  useEffect(() => {
    const value = salarioValue * (pctCtbSup / 100)

    setVlrCtbSup(value)
  }, [pctCtbSup, salarioValue])

  const mudarSalario = useCallback(valor => {
    const v = valor.replace(',', '').replaceAll('.', '')
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(v) || v === '' || v === undefined) {
      setSalarioValue(0)
    } else {
      const m = Math.floor(v.length - 2)
      const a = `${v.substr(0, m)}.${v.substr(m)}`
      const f = parseFloat(a)
      setSalarioValue(f)
    }
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasicaF1: vlrCtbBasicaF1,
          contribuicaoBasicaF2: vlrCtbBasicaF2,
          contribuicaoBasicaF3: vlrCtbBasicaF3,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora,
          pctContribuicaoBasicaF1: pctCtbBasF1,
          pctContribuicaoBasicaF2: pctCtbBasF2,
          pctContribuicaoBasicaF3: pctCtbBasF3,
          contribuicaoSuplementar: vlrCtbSup,
          pctContribuicaoSuplementar: pctCtbSup,
        })

        if (flagEdit === 'S') {
          setFlagEdit('N')
          history.push('/resume')
        } else {
          // history.push('/investor-profile')
          history.push('/investor-quiz')
        }
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      flagEdit,
      history,
      pctCtbBasF1,
      pctCtbBasF2,
      pctCtbBasF3,
      pctCtbSup,
      salarioValue,
      setFlagEdit,
      setUserDetails,
      userDetails,
      vlrCtbBasicaF1,
      vlrCtbBasicaF2,
      vlrCtbBasicaF3,
      vlrCtbPatrocinadora,
      vlrCtbSup,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const marksF1 = []
  for (let i = 0; i <= 5; i += 0.5) {
    marksF1.push({ value: i, label: `${i.toString().replace('.', ',')}%` })
  }
  const marksF2 = []
  for (let i = 0; i <= 12; i += 0.5) {
    marksF2.push({
      value: i,
      label: Math.floor(i) === i ? `${i.toString().replace('.', ',')}%` : '',
    })
  }
  const marksF3 = []
  for (let i = 0; i <= 7.5; i += 0.5) {
    marksF3.push({ value: i, label: `${i.toString().replace('.', ',')}%` })
  }

  const marksSup = []
  for (let i = 0; i <= 10; i += 0.5) {
    marksSup.push({
      value: i,
      label: Math.floor(i) === i ? `${i.toString().replace('.', ',')}%` : '',
    })
  }
  const steps = [
    'Select master blaster campaign settings',
    'Create an ad group',
    'Create an ad',
  ]

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            salario:
              userDetails.salario === undefined
                ? ''
                : ValorMascarar(userDetails.salario.toFixed(2).toString()),
          }}
        >
          <Content>
            <FormSteps currentStep={0} />
            <div className="app_title">
              <strong>Contribuição básica</strong>
              <RiQuestionLine onClick={() => setIsModalOpen(true)} />
            </div>
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário base R$?"
              onChange={e => mudarSalario(e.target.value)}
            />
            <Collapse in={salarioValue !== 0} className="collapse">
              <URContent>
                <div className="UR-info">
                  <p>Contribuição mensal</p>
                </div>
                <URBox>
                  <InputRange
                    name="rangeContribBasicaF1"
                    functionChange={e => {
                      setPctCtbBasF1(e)
                      setPctCtbBasF2(0)
                      setPctCtbBasF3(0)
                      setPctCtbSup(0)
                    }}
                    start={0}
                    end={5}
                    initialValue={pctCtbBasF1}
                    step={0.1}
                    key={0}
                    marks={marksF1}
                    valueLabelDisplay="off"
                    disabled={salarioValue < SUA}
                  />
                  <strong>
                    {formatValue(vlrCtbBasicaF1)} ({pctCtbBasF1}%)
                  </strong>
                </URBox>
                {salarioValue > faixa1 ? (
                  <>
                    <Line />
                    <URBox>
                      <InputRange
                        name="rangeContribBasicaF2"
                        functionChange={e => {
                          setPctCtbBasF2(e)
                          setPctCtbBasF3(0)
                          setPctCtbSup(0)
                        }}
                        start={0}
                        end={12}
                        initialValue={pctCtbBasF2}
                        step={0.1}
                        key={0}
                        marks={marksF2}
                        valueLabelDisplay="off"
                        disabled={pctCtbBasF1 < 5}
                      />
                      <strong>
                        {formatValue(vlrCtbBasicaF2)} ({pctCtbBasF2}%)
                      </strong>
                    </URBox>
                  </>
                ) : (
                  <></>
                )}
                {salarioValue > faixa2 ? (
                  <>
                    <Line />
                    <URBox>
                      <InputRange
                        name="rangeContribBasicaF3"
                        functionChange={e => {
                          setPctCtbBasF3(e)
                          setPctCtbSup(0)
                        }}
                        start={0}
                        end={7.5}
                        initialValue={pctCtbBasF3}
                        step={0.1}
                        key={0}
                        marks={marksF3}
                        valueLabelDisplay="off"
                        disabled={pctCtbBasF2 < 10}
                      />
                      <strong>
                        {formatValue(vlrCtbBasicaF3)} ({pctCtbBasF3}%)
                      </strong>
                    </URBox>
                  </>
                ) : (
                  <></>
                )}
              </URContent>
            </Collapse>

            <Line />

            <InfoContent>
              <div>
                <p>Contribuição básica do Participante: </p>
                <strong>
                  {formatValue(
                    vlrCtbBasicaF1 + vlrCtbBasicaF2 + vlrCtbBasicaF3,
                  )}
                </strong>
              </div>
              <div>
                <p>Repique da empresa: </p>
                <strong>{formatValue(vlrCtbPatrocinadora)}</strong>
              </div>
            </InfoContent>
          </Content>

          <Collapse
            in={
              salarioValue > 0 &&
              ((salarioValue <= faixa1 && pctCtbBasF1 >= 5) ||
                (salarioValue > faixa1 &&
                  salarioValue <= faixa2 &&
                  pctCtbBasF2 >= 12) ||
                (salarioValue > faixa2 && pctCtbBasF3 >= 7.5))
            }
          >
            <Content>
              <div className="app_title">
                <strong>Contribuição Voluntária</strong>
                <RiQuestionLine onClick={() => setIsModalOpen(true)} />
              </div>
              <p>
                Para contribuir ainda mais com a formação da sua reserva,
                cadastre uma contribuição adicional, correspondente a um
                percentual incidente sobre o seu salário. Sobre a Contribuição
                Voluntária não haverá contrapartida da Patrocinadora.
              </p>
              <Collapse in={!visibility} className="collapse">
                <URContent>
                  <div className="UR-info">
                    <p>
                      Com qual % sobre o seu salário você deseja contribuir
                      adicionalmente?
                    </p>
                  </div>
                  <URBox>
                    <InputRange
                      name="rangeContribSuplementar"
                      functionChange={e => setPctCtbSup(e)}
                      start={0}
                      end={10}
                      initialValue={pctCtbSup}
                      step={0.1}
                      key={0}
                      marks={marksSup}
                      valueLabelDisplay="off"
                    />
                    <strong>{pctCtbSup}%</strong>
                  </URBox>
                  <Line />
                  <InfoContent>
                    <div>
                      <p>Valor da contribuição voluntária:</p>
                      <strong>{formatValue(vlrCtbSup)}</strong>
                    </div>
                  </InfoContent>
                </URContent>
                <Line />
              </Collapse>

              <NoContribCheck>
                <div>
                  <input
                    name="checkbox"
                    type="checkbox"
                    checked={visibility}
                    onChange={e => {
                      setVisibility(e.target.checked)
                      setPctCtbSup(0)
                      setVlrCtbSup(0)
                    }}
                  />
                  <small
                    onClick={() => {
                      setVisibility(!visibility)
                      setPctCtbSup(0)
                      setVlrCtbSup(0)
                    }}
                  >
                    Não quero cadastrar uma contribuição voluntária.
                  </small>
                </div>
              </NoContribCheck>

              <div className="rules">
                <small>
                  Está em dúvida? Não se preocupe, depois você poderá alterar
                  este valor sempre que desejar.
                </small>
              </div>
            </Content>
          </Collapse>
          <Content>
            <InfoContent>
              <div>
                <p>Desconto total:</p>
                <strong>
                  {formatValue(
                    vlrCtbBasicaF1 +
                    vlrCtbBasicaF2 +
                    vlrCtbBasicaF3 +
                    vlrCtbSup,
                  )}
                </strong>
              </div>
            </InfoContent>
          </Content>
        </Form>

        <Button
          type="submit"
          color="green"
          onClick={handleClick}
          disabled={
            pctCtbBasF1 === null ||
            pctCtbBasF2 === null ||
            pctCtbBasF3 === null ||
            pctCtbSup === null ||
            salarioValue < 1 ||
            (pctCtbBasF1 === 5 && vlrCtbSup === 0 && !visibility)
          }
        >
          Próximo
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/welcome')}>
          &lt; Anterior
        </BtnVoltar>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <FiX onClick={handleCloseModal} />
          <strong>SALÁRIO BASE</strong>
          <p>
            O salário informado corresponde ao último Salário Base de INSS,
            atualizado e registrado no sistema conforme dados informados pelo RH
            da Cargill. As contribuições mensais futuras serão calculadas com
            base no salário base de INSS atualizado (composto por todas as
            variáveis processadas na folha de pagamento, como férias, 13º, horas
            extras, entre outros).
          </p>
          <Line />
          <strong>CONTRIBUIÇÃO BÁSICA</strong>
          <p>
            A sua Contribuição Básica mensal é calculada de acordo com seu
            salário de participação e com percentual definido na adesão. Para
            cada Contribuição Básica feita, a empresa fará um depósito adicional
            em seu nome.
          </p>
          <Line />
          <strong>CONTRIBUIÇÃO VOLUNTÁRIA</strong>
          <p>
            A Contribuição Voluntária pode ser realizada mensalmente até o
            limite máximo de 10% do seu salário de participação, porém ela não
            possui o repique da empresa.
          </p>
        </Modal>
      </Container>
    </>
  )
}

export default ContributionP1
