import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const Spinner = styled.div`
  width: 30px;
  height: 30px;
  border: solid 4px ${({ theme }) => theme.colors.primary};
  border-bottom-color: ${({ theme }) => theme.colors.mainColor};
  border-radius: 50%;
  animation: ${rotate} 0.95s linear infinite;
`

export const Text = styled.p`
  text-align: center;
  margin-top: 5px;
`