import { Step, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { StepIconProps } from '@material-ui/core/StepIcon'
import StepConnector from '@material-ui/core/StepConnector'
import clsx from 'clsx'
import React from 'react'
import { Label } from './styles'

interface Props {
  currentStep: number
}

const steps = [
  { label: 'Contribuição' },
  { label: 'Perfil de Investidor' },
  { label: 'PPE/FATCA' },
  { label: 'Beneficiários' },
  { label: 'Confirmação' },
]

const Connector = withStyles({
  alternativeLabel: {
    top: 14,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#055535',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#055535',
    },
  },
  line: {
    borderColor: '#eee',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector)

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#eee',
    color: '#444',
    width: '1.875rem',
    height: '1.875rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: 700,
    zIndex: 1,
    fontFamily: 'Arial',
    marginBottom: '-10px',
  },
  active: {
    backgroundColor: '#055535',
    color: '#eee',
  },
  completed: {
    backgroundColor: '#055535',
    color: '#eee',
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function LabelIcon({ active, completed, icon }: StepIconProps) {
  const classes = useStepIconStyles()

  return (
    <span
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icon}
    </span>
  )
}

export const FormSteps: React.FC<Props> = ({ currentStep }) => {
  return (
    <>
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        connector={<Connector />}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={LabelIcon}>
              <Label>{step.label}</Label>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  )
}
