import React from 'react'

import icon1 from '../../assets/iconFim1.gif'
import icon2 from '../../assets/iconFim2.gif'
import icon3 from '../../assets/iconFim3.gif'
import icon4 from '../../assets/iconFim4.gif'

import Header from '../../components/Header'
import { Container, Content, Line } from './styles'
import usePersistedState from '../../hooks/usePersistedState'
import { UserData } from '../../utils/interfaces'

const End: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [flagPlano] = usePersistedState<number | null>('flagPlano', null)

  let dcrPlano = ''
  if (flagPlano === 1) {
    dcrPlano = 'Plano de Aposentadoria Cargill'
  } else if (flagPlano === 5) {
    dcrPlano = 'Plano de Benefícios Cargill'
  } else if (flagPlano === 4) {
    dcrPlano = 'Plano Mais Vida'
  }

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="inicio">
            <p>
              <b>{userData.name.split(' ')[0]}</b>,
              <br />A CargillPrev parabeniza sua inscrição no
            </p>
            <strong>{dcrPlano}</strong>
          </div>
          <table className="icones">
            <tr>
              <td>
                <img src={icon1} alt="img1" />
              </td>
              <td>
                <p>
                  Seu termo de adesão, que formaliza sua participação na CargillPrev está disponível no Portal do Participante (Acesso Restrito).
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <img src={icon2} alt="img2" />
              </td>
              <td>
                <p>
                  O desconto de suas contribuições inicia na próxima abertura de
                  calendário da folha de pagamento.
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <img src={icon3} alt="img3" />
              </td>
              <td>
                <p>
                  Consulte o Estatuto da CargillPrev, o Regulamento do Plano de
                  Benefícios Cargill e o Regulamento de Investimento no site.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <img src={icon4} alt="img4" />
              </td>
              <td>
                <p>
                  Acesse o Portal do Participante&nbsp;
                  <a href="https://cargillprev.com.br/acesso-restrito/">
                    clicando aqui
                  </a>
                  .
                </p>
              </td>
            </tr>
          </table>
          <Line />
          <div className="footer">
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="https://CargillPrev.com.br/">www.CargillPrev.com.br</a>
          </div>

          {/*
          <img src={endImage} alt="endImage" />

          <p>
            Confira seu e-mail. Enviamos uma mensagem com instruções de acesso
            ao autoatendimento.
          </p>
          */}
        </Content>
      </Container>
    </>
  )
}

export default End
