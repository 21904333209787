/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FormHandles, Scope } from '@unform/core'
import { useHistory } from 'react-router-dom'
import _cloneDeep from 'lodash/cloneDeep'
import { Form } from '@unform/web'

import { FiCheck, FiX } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Participant } from '../../utils/interfaces'
import { Container, Content, BenefBox, Line, ContentBenef } from './styles'
import { FormSteps } from '../../components/FormSteps'

interface CopiaDependent {
  data: {
    name: string
    cpf: string
    birthdate: string
  }
  details: {
    tipVinculo: string
    tipoBen: string
    grauParentesco: string
    dcrGrauParentesco: string
    proporcao: number
  }
}

const ParticipantsList: React.FC = () => {
  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const dependentsListCopy: CopiaDependent[] = _cloneDeep(participants)
  const [partLength, setPartLength] = useState(participants.length)
  const arr = participants.map(participant => participant.details.proporcao)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }
  const [totalProporcao, setTotalProporcao] = useState(soma)

  useEffect(() => {
    setTotalProporcao(soma)
  }, [soma])

  const teste = useCallback(
    async arrAux => {
      await setParticipants([...arrAux])
      setTotalProporcao(100)
    },
    [setParticipants],
  )

  const handleProporcionalizar = useCallback(async () => {
    const value = (100 / partLength).toFixed(2)

    const contador = partLength

    for (let i = 0; i < contador; i += 1) {
      dependentsListCopy[i].details.proporcao = parseFloat(value)
    }
    teste(dependentsListCopy)
  }, [dependentsListCopy, partLength, teste])

  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
      setPartLength(participants.length)
    },
    [participants, setParticipants],
  )

  useEffect(() => {
    setPartLength(participants.length)
  }, [setParticipants, participants, handleRemove])

  const handleSubmit = useCallback(() => {
    history.push('/address-infos')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormSteps currentStep={3} />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <BenefBox>
              {participants.length > 0 ? (
                <h3>Confira seus beneficiários</h3>
              ) : (
                <h3>Nenhum beneficiário informado.</h3>
              )}

              {participants.map((participant, idx) => (
                <ContentBenef key={Math.random()}>
                  <Scope path={`parts[${idx}]`}>
                    <FiX onClick={() => handleRemove(idx)} />
                    <div>
                      <div>
                        <strong>Vínculo: </strong>
                        <label>{participant.details.dcrGrauParentesco}</label>
                      </div>
                      <div>
                        <strong>Nome: </strong>
                        <label>{participant.data.name}</label>
                      </div>
                      <div>
                        <strong>Nascimento: </strong>
                        <label>
                          {participant.data.birthdate
                            .toString()
                            .split('-')
                            .reverse()
                            .join('/')}
                        </label>
                      </div>
                      <div>
                        <strong>CPF: </strong>
                        <label>{participant.data.cpf}</label>
                      </div>
                    </div>
                  </Scope>
                  <Line />
                </ContentBenef>
              ))}
            </BenefBox>
          </Form>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="green"
          width="large"
          onClick={handleClickSubmit}
        >
          {partLength === 0 ? (
            <span>Não possuo beneficiários</span>
          ) : (
            <>
              <FiCheck size={45} />
              <span>Pronto! Avançar</span>
            </>
          )}
        </Button>

        {partLength === 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            width="medium"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar beneficiário
          </Button>
        ) : (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            width="medium"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar mais um beneficiário
          </Button>
        )}
        {/* {totalProporcao === 100 ? (
          <BenefBox>
            <p>
              Você distribuiu 100% da proporção entre seus beneficiários. Caso
              queira adicionar mais beneficiários será necessário remover algum
              dos já cadastrados e inserir novamente.
            </p>
          </BenefBox>
        ) : (
          <></>
        )}
        {totalProporcao !== 100 && partLength > 0 ? (
          <>
            <BenefBox>
              <p>
                Você ainda não distribuiu 100% da proporção entre seus
                beneficiários. Adicione mais beneficiários ou{' '}
                <span onClick={() => handleProporcionalizar()}>
                  clique aqui
                </span>{' '}
                para distribuir igualmente entre todos os informados.
              </p>
            </BenefBox>
            <Button
              type="button"
              fontSize="normal"
              color="white"
              width="large"
              onClick={() => history.push('/new-participant')}
            >
              Adicionar mais um beneficiário
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="green"
              width="large"
              onClick={handleClickSubmit}
            >
              {partLength === 0 ? (
                <span>Não possuo beneficiários</span>
              ) : (
                <>
                  <FiCheck size={45} />
                  <span>Pronto! Avançar</span>
                </>
              )}
            </Button>
          </>
        )} */}
      </Container>
    </>
  )
}

export default ParticipantsList
