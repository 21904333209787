import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FiArrowRightCircle } from 'react-icons/fi'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content, InfoValuesBox } from './styles'
import ColoredBox from '../../components/ColoredBox'
import calculaIdade from '../../utils/calculaIdade'
import usePersistedState from '../../hooks/usePersistedState'
import { UserDetails, ConfigData } from '../../utils/interfaces'
import clientConfig from '../../services/clientConfig'
import ModalBox from '../../components/Modal'

const BemVindo: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [tipoPlano] = usePersistedState<string>('tipoPlano', 'N')
  const [, setFlagPlano] = usePersistedState<number | null>('flagPlano', null)

  const { codCliente, tipo } = clientConfig()

  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [warningText, setWarningText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const handlePrevidencial = useCallback(async () => {
    console.log(tipoPlano)
    if (tipoPlano === 'N') {
      setIsModalOpen(true)
      setWarningText('Você já possui adesão ao plano ativa na Cargillprev.')
    } else if (tipoPlano === 'P1') {
      await setFlagPlano(1)
      await setConfigData({
        ...configData,
        codCliente,
        tipo,
        plano: 1,
      })
      history.push('/contribution-p1')
    } else if (tipoPlano === 'P2') {
      await setFlagPlano(5)
      await setConfigData({
        ...configData,
        codCliente,
        tipo,
        plano: 5,
      })
      history.push('/contribution-p2')
    }
  }, [
    tipoPlano,
    setFlagPlano,
    setConfigData,
    configData,
    codCliente,
    tipo,
    history,
  ])

  const handleAssistencial = useCallback(async () => {
    if (userDetails.age < 40) {
      setIsModalOpen(true)
      setWarningText(
        'Este plano só pode ser aderido por quem tem mais de 40 anos.',
      )
    } else {
      await setFlagPlano(4)
      await setConfigData({
        ...configData,
        codCliente,
        tipo,
        plano: 4,
      })
      history.push('/contribution-p3')
    }
  }, [
    codCliente,
    configData,
    history,
    setConfigData,
    setFlagPlano,
    tipo,
    userDetails.age,
  ])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Bem-vindo ao espaço de adesão CargillPrev!</strong>
          <span>
            Aqui você seleciona o plano e dá início a sua participação na
            entidade. <br />
            Aproveite todos os benefícios de ser um participante Cargillprev!
            <br />
          </span>
          <span>Vamos lá?</span>
          <div className="values-box">
            <InfoValuesBox color="green">
              <h3>PLANO PREVIDENCIAL</h3>
              <p>
                Com o plano Previdencial da CargillPrev, você tem a
                possibilidade de planejar seu futuro com segurança e
                rentabilidade garantida. Todas as suas contribuições e
                contrapartidas efetuadas pela patrocinadora, representam o saldo
                do seu futuro benefício.
              </p>
              <Button
                color="green2"
                onClick={handlePrevidencial}
                width="small"
                fontSize="small"
              >
                Quero aderir
              </Button>
            </InfoValuesBox>

            <InfoValuesBox color="green2">
              <h3>PLANO MAIS VIDA</h3>
              <p>
                Com o Plano Mais Vida, você constitui reservas que serão
                utilizadas para pagamento de despesas com saúde. É elegível ao
                Plano Mais Vida da CargillPrev, funcionários com idade igual ou
                superior a 40 anos.
              </p>
              <Button
                color="green"
                onClick={handleAssistencial}
                width="small"
                fontSize="small"
              >
                Quero aderir
              </Button>
            </InfoValuesBox>
          </div>
          {/* <small>
            O Plano de Benefícios II é um plano de previdência oferecido aos
            empregados que não possuem vinculação a outro plano de benefícios
            administrado pela ReckittPrev ou a outro plano de benefícios
            patrocinado pelas patrocinadoras.
          </small>
          <small>
            Como participante do Plano de Benefícios II, você contribuirá com
            uma parte e a empresa com o mesmo valor, limitado as regras
            regulamentares. O objetivo principal é formar uma reserva de
            poupança e garantir maior segurança financeira para o seu futuro.
          </small> */}
        </Content>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default BemVindo
