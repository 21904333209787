import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Base64, toBase64 } from 'js-base64'

import { RiQuestionLine } from 'react-icons/ri'
import Modal from 'react-modal'
import usePersistedState from '../../hooks/usePersistedState'
import Button from '../../components/Button'
import Header from '../../components/Header'
import infoPerfil from '../../assets/infoPerfil.png'

import {
  UserData,
  ConfigData,
  ErroProps,
  UserDetails,
} from '../../utils/interfaces'
import { Container, Content, BtnVoltar, QuizContainer, QuizContent, Question, Form, Option, CheckBox, Footer, BackButton, NextButton, Line } from './styles'
import api from '../../services/api'
import { FormSteps } from '../../components/FormSteps'
import ModalBox from '../../components/Modal'
import { Loading } from '../../components/Loading'
import { FiX } from 'react-icons/fi'

type Opcao = {
  codOpcao: number;
  peso: number;
  dcrOpcao: string;
}

interface QuizData {
  perguntas: {
    dcr: string;
    tipo: 'O' | 'I';
    imagem?: string;
    dcrDetalhe?: string;
    opcoes?: Opcao[];
  }[];
  perfis: {
    min: number;
    max: number;
    dcr: string;
    sigla: string;
    html: string;
  }[];
}

Modal.setAppElement('#root')

const InvestorQuiz: React.FC = () => {
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [selectedQuiz, setSelectedQuiz] = usePersistedState<string>(
    'selectedQuiz',
    '',
  )
  const [dcrSelectedQuiz, setDcrSelectedQuiz] = usePersistedState<string>(
    'dcrSelectedQuiz',
    '',
  )
  const [warningText, setWarningText] = useState(<></>)
  const [isModalBoxOpen, setIsModalBoxOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [flagPlano] = usePersistedState<number | null>('flagPlano', null)
  const [step, setStep] = useState(0)
  const [pontos, setPontos] = useState(0)
  const [payload, setPayload] = useState<{ [x: string]: Opcao }>({})
  const [perfil, setPerfil] = useState({
    min: 0,
    max: 60,
    dcr: 'Super Conservador',
    sigla: 'SCSV',
    html: '<strong>Super Conservador</strong>'
  })
  const [quizData, setQuizData] = useState<QuizData>({ perguntas: [], perfis: [] })
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const dcrCliente =
    configData.codCliente === 2054 ? 'CargillPrev' : 'HMG_CargillPrev'

  function handleContinue() {
    if (step === quizData.perguntas.length) { // Continua se tiver concluído o quiz
      history.push('/investor')
    } else {
      setWarningText(
        <p>Você precisa finalizar o questionário para avançar.</p>,
      )
      setIsModalBoxOpen(true)
    }
  }

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }
  function handleCloseModalBox(): void {
    setIsModalBoxOpen(false)
  }

  const handleTextInfo = useCallback(() => {
    setWarningText(
      <>
        <FiX onClick={handleCloseModal} />
        <div>
          <strong>SUPER CONSERVADOR:</strong>
          <p>
            Indicado para quem está a pouquíssimo tempo de se aposentar ou que tem baixa tolerância aos riscos financeiros.
          </p>
          <Line />
          <strong>CONSERVADOR:</strong>
          <p>
            Destinado aos participantes quem têm um pouco mais de tempo para se aposentar ou com alguma tolerância aos riscos financeiros.
          </p>
          <Line />
          <strong>MODERADO:</strong>
          <p>
            Indicado para quem ainda tem um certo tempo para se aposentar, está mais aberto aos riscos financeiros e busca um retorno acima da média no longo prazo.
          </p>
          <Line />
          <strong>ARROJADO:</strong>
          <p>
            Ideal para o participante que tem muito tempo para se aposentar e aceita correr riscos financeiros para obter um retorno bem acima da média no futuro.
          </p>
          <Line />
          <strong>SUPER ARROJADO:</strong>
          <p>
            Indicado para o participante que entende e aceita a dinâmica do mercado financeiro e busca, através da alta exposição ao risco, 
            uma rentabilidade superior à dos demais no longo prazo.
          </p>
          <Line />
          <strong>CICLO DE VIDA:</strong>
          <p>
            Recomendado para quem prefere delegar a gestão de risco individual à CargillPrev. É um modelo dinâmico em que os investimentos 
            se alteram automaticamente de acordo com a idade entre os perfis SC, CO, MO e AR.
          </p>
        </div>
      </>)
    setIsModalOpen(true)
  }, [])

  const handleVoltar = useCallback(() => {
    if (flagPlano === 1) {
      history.push('/contribution-p1')
    } else if (flagPlano === 5) {
      history.push('/contribution-p2')
    } else if (flagPlano === 4) {
      history.push('/contribution-p3')
    }
  }, [flagPlano, history])

  function handleNextStep(numSteps: number) {
    if (step === 0 || step === 1) {
      setStep(step + 1)
      return
    }

    if (step < numSteps && payload[`pergunta-${step - 1}`]?.peso >= 0) {
      setPontos(pontos + payload[`pergunta-${step - 1}`]?.peso)
      setStep(step + 1)
    }
  }

  function handleBackStep() {
    if (step === 1 || step === 2) {
      setStep(step - 1)
      return
    }

    if (step > 2) {
      setPontos(pontos - payload[`pergunta-${step - 2}`]?.peso)
      setStep(step - 1)
    }
  }

  function handleSelection(opcao: Opcao, index: number) {
    let selectedOption = {
      [`pergunta-${index}`]: opcao
    }
    setPayload({ ...payload, ...selectedOption })
  }

  const handleBuscaQuestionario = useCallback(async () => {
    const parametros = Base64.encode(
      `{"token": "${configData.token}",
        "cliente":"${configData.codCliente}"}`,
    )
    const parametrosFinal = Base64.encode(parametros)

    api.get(
      `wsAvaliacaoPerfilInvestidor.rule?sys=ADZ&Entrada=${parametrosFinal}`,
    )
      .then(res => {
        if (res.status === 200) {
          setQuizData(res.data)
          setIsLoading(false)
        }
      })
      .catch(res => {
        if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão com o servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 401') {
          setWarningText(
            <p>Você precisa finalizar o questionário para avançar.</p>,
          )
          setIsModalBoxOpen(true)
        } else if (res.message === 'Request failed with status code 408') {
          alert('Tempo de sessão expirado')
          history.push('/')
        } else {
          setWarningText(
            <p>Ops, algo deu errado. Tente novamente mais tarde.</p>,
          )
          setIsModalBoxOpen(true)
        }
      })
  }, [configData])

  useEffect(() => {
    let tempPerfil = quizData.perfis.filter(perfil => {
      return pontos >= perfil.min && pontos <= perfil.max
    })
    setPerfil(tempPerfil[0])

    if (step > 0 && step === quizData.perguntas.length) {
      setDcrSelectedQuiz(tempPerfil[0] ? tempPerfil[0].dcr.toLowerCase().replace('perfil ', '') : '')
      setSelectedQuiz(tempPerfil[0] ? tempPerfil[0].sigla.toUpperCase().trim() : '')
      setUserDetails({
        ...userDetails,
        investor: tempPerfil[0] ? tempPerfil[0].sigla.toUpperCase().trim() : ''
      })
    }
  }, [pontos, step])

  useEffect(() => {
    handleBuscaQuestionario()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <FormSteps currentStep={1} />
          <div className="app_title">
            <strong>Avaliação do Perfil de Investidor</strong>
            <RiQuestionLine onClick={() => handleTextInfo()} />
          </div>
          {/* <strong>Perfil de Investidor</strong> */}
          {/* <iframe
            src={`${urlInv}`}
            title="Perfil Investidor"
            referrerPolicy="unsafe_url"
          /> */}

          {isLoading
            ? <Loading />
            : quizData
              ? <QuizContainer>
                {step < quizData.perguntas.length // Exibe etapas enquanto step for menor do que a quantidade de etapas, quando não, exibe o resultado do quiz
                  ? quizData.perguntas[step].tipo === 'I' // Se tipo for igual a I exibe container de imagem, caso contrário exibe container de seleção de opções
                    ? <QuizContent>
                      <img src={quizData.perguntas[step].imagem} alt={quizData.perguntas[step].dcr} />
                    </QuizContent>
                    : <QuizContent>
                      <Question>{quizData.perguntas[step].dcr}</Question>
                      <Form>
                        {quizData.perguntas[step].opcoes?.map((opcao, index) => (
                          <Option key={index} onClick={() => handleSelection(opcao, step - 1)}>
                            <CheckBox
                              type='radio'
                              name={`pergunta-${step - 1}`}
                              onChange={() => { }}
                              checked={payload[`pergunta-${step - 1}`]?.peso === opcao.peso} />
                            {opcao.dcrOpcao}
                          </Option>
                        ))}
                      </Form>
                    </QuizContent>
                  : // EXIBE RESULTADO DO QUIZ
                  <QuizContent>
                    <p className="title">Parabéns! Você concluiu com sucesso a Avaliação do Perfil de Investidor.</p>
                    <div className="result" dangerouslySetInnerHTML={{ __html: perfil.html }} />
                  </QuizContent>}

                <Footer>
                  {step > 0 // BackButton oculto na primeira página
                    ? <BackButton
                      onClick={() => handleBackStep()}>
                      Anterior
                    </BackButton>
                    : null}
                  {step < quizData.perguntas.length // NextButton oculto na última página
                    ? <NextButton
                      onClick={() => handleNextStep(quizData.perguntas.length)}>
                      {step === quizData.perguntas.length - 1 ? 'Finalizar' : 'Próxima' /* Altera o texto do botão na última tela do quiz */}
                    </NextButton>
                    : null}
                </Footer>
              </QuizContainer>
              : null}
        </Content>
        <Button type="button" color="green" onClick={handleContinue}>
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => handleVoltar()}>
          &lt; Anterior
        </BtnVoltar>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content modal-content-image"
        >
          {warningText}
        </Modal>
        <ModalBox isOpen={isModalBoxOpen} onRequestClose={handleCloseModalBox}>
          {warningText}
        </ModalBox>
      </Container>
    </>
  )
}

export default InvestorQuiz
