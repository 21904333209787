import React from 'react'
import { Container, Spinner, Text } from './styles'

export function Loading() {
  return (
    <Container>
      <Spinner />
      <Text>Aguarde...</Text>
    </Container>
  )
}