import React, { useEffect, useRef } from 'react'

import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

// import { useField } from '@unform/core'
import { Container } from './styles'

const PrettoSlider = withStyles({
  root: {
    color: '#139C65',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundImage: 'linear-gradient(-60deg,#14A86D, #139C65)',
    border: '0px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 7px)',
  },
  track: {
    height: 8,
    borderRadius: 2,
    backgroundImage: 'linear-gradient(-60deg,#14A86D, #139C65)',
  },
  rail: {
    height: 8,
    borderRadius: 2,
  },
  markLabel: {
    fontSize: 10,
  },
})(Slider)

interface Props {
  name: string
  start: number
  end: number
  initialValue: number
  functionChange: React.Dispatch<React.SetStateAction<number>>
  step?: number
  prefix?: string
  sufix?: string
  marks?: any
  valueLabelDisplay?: 'off' | 'on' | 'auto'
  showLabel?: boolean
  disabled?: boolean
}

const InputRange: React.FC<Props> = ({
  name,
  start,
  step = 1,
  end,
  functionChange,
  initialValue,
  prefix,
  sufix,
  marks,
  valueLabelDisplay = 'auto',
  showLabel = false,
  disabled = false,
  ...rest
}) => {
  // const inputRef = useRef<HTMLInputElement>(null)
  // const {
  //   fieldName,
  //   defaultValue,
  //   error,
  //   clearError,
  //   registerField,
  // } = useField(name)

  const handleChange = (e: any) => {
    functionChange(e.target.getAttribute('aria-valuenow'))
  }
  useEffect(() => {
    functionChange(initialValue)
  }, [functionChange, initialValue])

  return (
    <Container>
      {showLabel && (
        <div>
          <span>
            {!!prefix && prefix} {start} {!!sufix && sufix}
          </span>

          <span>
            {!!prefix && prefix} {end} {!!sufix && sufix}
          </span>
        </div>
      )}
      <PrettoSlider
        min={start}
        max={end}
        step={step}
        defaultValue={initialValue}
        onChange={e => handleChange(e)}
        // onChangeCommitted={e => handleChange(e)}
        marks={marks}
        // value={initialValue}
        valueLabelDisplay={valueLabelDisplay}
        disabled={disabled}
        {...rest}
      />
    </Container>
  )
}

export default InputRange
